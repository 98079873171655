import "./Home.scss";
import Gear from '../../images/Gear';
import Truck from '../../images/Truck';
import Key from '../../images/Key';
import LockedCar from "../../images/LockedCar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Service from "./Service";
import Roadside from "./Roadside";
import Appointment from "./Appointment";
import Warranty from "./Warranty";
import WarrantyServiceContractImage from "../records/WarrantyServiceContractImage";

function Home({selectedHomeServicePage, handleHomeSelect}) {
  const renderPage = () => {
    if (selectedHomeServicePage === "buttons") {
      return (
        <div className="home-actions">
          <div className="service-button" onClick={() => {handleHomeSelect("service")}}>
            <div className="button-image-white">
              <Gear button-type="service-button-gear"/>
            </div>
            <div className="button-text-black">
              Service / Repair
            </div>
          </div>

          <div className="service-button" onClick={() => {handleHomeSelect("roadside")}}>
            <div className="button-image-white">
              <Truck button-type="service-button-truck"/>
            </div>
            <div className="button-text-black">
              Roadside Assistance
            </div>
          </div>

          <div className="service-button" onClick={() => {handleHomeSelect("appointment")}}>
            <div className="button-image-white">
              <Key button-type="service-button-key"/>
            </div>
            <div className="button-text-black">
              Schedule Service
            </div>
          </div>

          <div className="service-button" onClick={() => {handleHomeSelect("warranty")}}>
            <div className="button-image-white">
              <WarrantyServiceContractImage button-type="service-button-locked-car" />
            </div>
            <div className="button-text-black">
              Warranty Protection
            </div>
          </div>
        </div>
      );
    } else if (selectedHomeServicePage === "service") {
      return <Service />
    } else if (selectedHomeServicePage === "roadside") {
      return <Roadside />
    } else if (selectedHomeServicePage === "appointment") {
      return <Appointment />
    } else if (selectedHomeServicePage === "warranty") {
      return <Warranty />
    } else {
      return (
        <div>in the else</div>
      )
    }
  }

  return (
    <div className="home">
      {renderPage()}
    </div>
  )
}

export default Home;