import { useSelector } from "react-redux";
import Key from "../../images/Key";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { postEvent } from "../../services/events/eventApi";
import { getUser } from "../../services/login/loginSlice";

function Appointment() {
  const currentUser = useSelector(getUser);

  const handleScheduleServiceClick = () => {
    postEvent("SCHEDULE_SERVICE", currentUser.email, localStorage.getItem('token'));
    window.open("https://www.beavertoyotacumming.com/service/schedule-service/");
  }

  return (
    <div className="service-page">
      <div className="service-page-header">
        <div className="service-page-header-text">
          Schedule service
        </div>
        <div className="service-page-header-schedule">
          <Key button-type="service-button-gear"/>
        </div>
      </div>
      <div className="service-page-body">
        <div className="service-page-bold">Help is just a quick call away</div>
        <br/>
        <div>
          <p>We’ve made it convenient and fast to schedule your next service visit.</p>

          <p>Simply select the date and time that works best for you. We can even arrange to pick up and drop off your vehicle or arrange shuttle transportation for you.</p>
        </div>

      </div>
      <div className="service-page-sub-info">

      </div>
      <div className="service-page-buttons">
        <button className="button-green" onClick={handleScheduleServiceClick}>
          <span className="button-green-icon"><FontAwesomeIcon icon={faCalendar} /></span>
          <span className="button-green-text">Schedule service</span>
        </button>
      </div>
      <div className="service-page-footer">

      </div>
    </div>
  )
}

export default Appointment;