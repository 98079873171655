import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRecordPage: "menu",
};

export const recordSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {
    setSelectedRecordPage: (state, action) => {
      state.selectedRecordPage = action.payload;
    }
  },
});
export const { setSelectedRecordPage } = recordSlice.actions;
export const selectedRecordPage = (state) => state.records.selectedRecordPage;

export default recordSlice.reducer;