import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Container, Form, Row, Col, Button, Table, Alert } from "react-bootstrap";
import RouteConstants from "../RouteConstants";
import { 
  userToEdit, 
  getUserAsync,
  updateUserAsync,
  resetSaveUserMessage,
  saveUserMessage,
  resetUserToEdit,
  updateEmail,
  updateFirstName,
  updateLastName,
  updatePhone,
  updateAdmin,
  updateDealer,
} from "src/services/dealer/dealerSlice";

import "./users.scss";

const EditUser = ({updateUrl, params}) => {
  const dispatch = useDispatch();
  const user_id = window.history.state.user.id;
  const user = useSelector(userToEdit);
  const message = useSelector(saveUserMessage);
  const selectedPage = params?.selectedPage;
  const searchCriteria = params?.criteria;

  const [showMessage, setShowMessage] = React.useState(false);

  useEffect(() => {
    dispatch(getUserAsync(user_id));
  }, []);

  useEffect(() => {
    if (message?.status === 'success' || message?.status === 'failed') {
      setShowMessage(true);
    }
  }, [message]);

  const handleEmailChange = (e) => {
    dispatch(updateEmail(e.target.value));
  }

  const handleFirstNameChange = (e) => {
    dispatch(updateFirstName(e.target.value));
  }

  const handleLastNameChange = (e) => {
    dispatch(updateLastName(e.target.value));
  }

  const handlePhoneNumberChange = (e) => {
    dispatch(updatePhone(e.target.value));
  }

  const handleAdminChange = (e) => {
    dispatch(updateAdmin(e.target.checked));
  }

  const handleDealerChange = (e) => {
    dispatch(updateDealer(e.target.checked));
  }

  const setShow = () => {
    setShowMessage(false);
    dispatch(resetSaveUserMessage());
  }

  const renderSaveMessage = () => {
    if (message?.status === 'success') {
      return (
        <Row>
          <Col>
            <Alert variant="success" onClose={() => setShow()} dismissible>{message.message}</Alert>
          </Col>
        </Row>
      )
    } else if (message?.status === 'failed') {
      return (
        <Row>
          <Col>
            <Alert variant="danger" onClose={() => setShow()} dismissible>{message.message} </Alert>
          </Col>
        </Row>
      )
    }
  }

  const backToUsers = () => {
    dispatch(resetSaveUserMessage());
    dispatch(resetUserToEdit());
    updateUrl(RouteConstants.USERS, null, {selectedPage: selectedPage, criteria: searchCriteria});
  }

  const handleSaveUser = () => {
    const editedUser = {
      id: user_id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      dealer: user.dealer,
      admin: user.admin,
    }

    dispatch(updateUserAsync(editedUser));
  }

  const renderVehicles = () => {
    if (user?.vehicles?.length > 0) {
      return user?.vehicles?.map((vehicle, index) => {
        return (
          <Row key={index}>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Vin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{vehicle.make}</td>
                    <td>{vehicle.model}</td>
                    <td>{vehicle.year}</td>
                    <td>{vehicle.vin}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        )
      })
    } else {
      return (
        <Row>
          <Col>
            No vehicles
          </Col>
        </Row>
      )
    }
  }


  return (
    <div>
      <Container className="navbar-margin">
        {renderSaveMessage()}

        <Row>
          <Col>
            <Button onClick={backToUsers} variant="link">Back to Users</Button>
          </Col>
        </Row>
        <Row className="edit-user-form">
          <Col>
            <Form>
              <fieldset>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" value={user?.email} onChange={handleEmailChange} placeholder="Enter email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" value={user?.first_name} onChange={handleFirstNameChange} placeholder="First Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" value={user?.last_name} onChange={handleLastNameChange} placeholder="Last Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" value={user?.phone} onChange={handlePhoneNumberChange} placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicAdmin">
                  <Form.Check type="checkbox" label="Admin" checked={user?.admin} onChange={handleAdminChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicDealer">
                  <Form.Check type="checkbox" label="Dealer" checked={user?.dealer} onChange={handleDealerChange} />
                </Form.Group>

                <Row>
                  <Col>
                    Vehicles: {renderVehicles()}
                  </Col>
                </Row>
                <Button variant="primary" type="button" onClick={handleSaveUser}>
                  Save User
                </Button>
              </fieldset>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EditUser;