import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountUpdate } from "./accountAPI";
import { login } from '../login/loginAPI';
import { 
  registerUser, 
  vinLookup, 
  registerDealer 
} from '../register/registerAPI';

import { 
  getCurrentUser, 
  addVehicle, 
  updateVehicle, 
  registerVin, 
  getProvisionedVins, 
  resendRegistrationSms,
} from "../account/accountAPI";

export const provisionVinAsync = createAsyncThunk(
  'account/provisionVin',
  async (payload) => {
    const response = await registerVin(payload.vin, payload.phonenumber);
    return response;
  }
);

export const resendRegistrationSmsAsync = createAsyncThunk(
  'account/resendRegistrationSms',
  async (payload) => {
    const response = await resendRegistrationSms(payload.vin, payload.phone);
    return response;
  }
);

export const getProvisionedVinsAsync = createAsyncThunk(
  'account/provisionedVins',
  async (payload, token) => {
    const response = await getProvisionedVins(payload.filter, payload.owner_filter, payload.page, payload.token);
    return response;
  }
);

export const getCurrentUserAsync = createAsyncThunk(
  'account/currentUser',
  async (payload) => {
    const response = await getCurrentUser(payload.token);
    return response;
  }
);

export const loginAsync = createAsyncThunk(
  'account/login',
  async (payload) => {
    const response = await login(payload.email, payload.password);
    return response;
  }
);

// Register the user with our API
export const registerAsync = createAsyncThunk(
  'register/register',
  async (payload) => {
    const response = await registerUser(
      payload.email, 
      payload.first_name, 
      payload.last_name, 
      payload.phone, 
      payload.terms_accepted);
  return response;
  }
)

// Dealer registration
export const dealerRegisterAsync = createAsyncThunk(
  'register/dealerRegister',
  async (payload) => {
    const response = await registerDealer(
      payload.email,
      payload.first_name,
      payload.last_name,
      payload.password,
      payload.dealer);
  return response;
  }
)

export const completeRegistrationAsync = createAsyncThunk(
  'register/completeRegistration',
  async (payload) => {
    const response = await registerUser(
      payload.email,
      payload.first_name,
      payload.last_name,
      payload.phone,
      payload.password);

  return response;
  }
)

export const vinLookupAsync = createAsyncThunk(
  'register/vinLookup',
  async (payload) => {
    const response = await vinLookup(
      payload.vin
    );

    return response;
  }
)

export const addVehicleAsync = createAsyncThunk(
  'account/addVehicle',
  async (payload) => {
    const response = await addVehicle(
      payload.vin,
      payload.year,
      payload.make,
      payload.model
    );
    return response;
  }
)

export const updateVehicleAsync = createAsyncThunk(
  'account/updateVehicle',
  async (payload) => {
    const response = await updateVehicle(
      payload.vehicle_id,
      payload.record_url
    );

    return response;
  }
)

export const updateAccountAsync = createAsyncThunk(
  'account/updateAccount',
  async (payload) => {
    const response = await accountUpdate(
      payload.user_id,
      payload.form_email,
      payload.form_first_name, 
      payload.form_last_name, 
      payload.form_phone,
      payload.token
    );

    return response;
  }
)

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    token: localStorage.getItem('token'),
    id: '',
    registrationError: "",
    status: 'idle',
    updateError: '',
    registration: false,
    passwordReset: false,
    resetPasswordToken: '',
    resetPasswordMessage: '',
    registrationStep: 1,
    provisionedVins: [],
    provisionVinMessage: "",
    smsMessageSent: "",
    provisionVin: "",
    provisionPhoneNumber: "",
    dealerRegistration: false,
  },
  reducers: {
    updateProvisionVin: (state, action) => {
      state.provisionVin = action.payload;
    },
    updateProvisionPhoneNumber: (state, action) => {
      state.provisionPhoneNumber = action.payload;
    },
    logout: (state) => {
      state.id = '';
      state.provisionedVins = [];
      state.provisionedVinsCurrentPage = 1;
      state.provisionedVinsTotalPages = 1;
      state.provisionedVinsTotalEntries = 0;
      state.provisionedVinsFilter = 'all';
      state.dealerRegistration = false;
      state.registration = false;
      state.passwordReset = false;
      state.resetPasswordMessage = '';
      state.resetPasswordToken = '';
      state.registrationError = '';
      state.smsMessageSent = "";
      state.token = '';
      state.first_name = '';
      state.last_name = '';
      state.email = '';
      state.phone = '';
      state.vehicleVin = '';
      state.vehicleMake = '';
      state.vehicleModel = '';
      state.vehicleYear = '';
      state.vehicleId = '';
      state.vehicleUrl = '';


      localStorage.removeItem('selectedVehicle');
      localStorage.removeItem('token');
      localStorage.removeItem('appState');
      window.history.replaceState(null, '', process.env.REACT_APP_BASE_URL);
    },
    logError: (state, action) => {
      state.loginError = action.payload;
    },
    register: (state, action) => {
      state.registration = action.payload;
    },
    updateFirstName: (state, action) => {
      state.first_name = action.payload.first_name
    },
    updateLastName: (state, action) => {
      state.last_name = action.payload.last_name
    },
    updatePhone: (state, action) => {
      state.phone = action.payload.phone
    },
    updateEmail: (state, action) => {
      state.email = action.payload.email
    },
    setVehicleUrl: (state, action) => {
      state.vehicleUrl = action.payload;
    },
    setRegistrationLevel: (state, action) => {
      state.registration = true;
      state.registrationStep = 1;
    },
    setDealerRegistration: (state, action) => {
      state.dealerRegistration = action.payload;
    },
    setPasswordReset: (state, action) => {
      state.passwordReset = action.payload;
    },
    setResetPasswordToken: (state, action) => {
      state.resetPasswordToken = action.payload;
    },
    skipImageUpload: (state) => {
      state.status = 'done'; 
      state.registration = false;
      state.registrationStep = 1;
    },
    updateVehicleVin: (state, action) => {
      state.vehicleVin = action.payload;
    },
    clearMessages: (state, action) => {
      state.smsMessageSent = "";
      state.provisionVinMessage = "";
    },
    dismissLoginError: (state) => {
      state.loginError = "";
    },
    dismissRegistrationError: (state) => {
      state.registrationError = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAccountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAccountAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.first_name = payload.user.first_name;
          state.last_name = payload.user.last_name;
          state.email = payload.user.email;
          state.phone = payload.user.phone;
          state.id = payload.user.id;
          state.admin = payload.user.admin;
          state.dealer = payload.user.dealer;
        } else {
          state.status = 'done'
          state.updateError = payload.message;
        }
      })
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.id = payload.user.id;
          state.first_name = payload.user.first_name;
          state.last_name = payload.user.last_name;
          state.email = payload.user.email;
          state.phone = payload.user.phone;       
          state.token = payload.token;
          state.admin = payload.user.admin;
          state.dealer = payload.user.dealer;
          state.loginError = "";

          // if we have at least a vehicle default our selected vehicle
          if ( payload.user.vehicles && payload.user.vehicles.length > 0 ) {
            state.vehicleId = payload.user.vehicles[0].id;
            state.selectedVehicle = payload.user.vehicles[0].id;
            state.vehicleMake = payload.user.vehicles[0].make;
            state.vehicleModel = payload.user.vehicles[0].model;
            state.vehicleYear = payload.user.vehicles[0].year;
            state.vehicleVin = payload.user.vehicles[0].vin;
            state.vehicleUrl = payload.user.vehicles[0].remote_url;

            localStorage.setItem('selectedVehicle', payload.user.vehicles[0].id);
          }
          
          localStorage.setItem('token', payload.token);
        } else if (payload.status === 401) {
          state.status = 'error';
          state.loginError = payload.message;
          state.token = undefined;
        } else {
          state.status = 'done';
        }
      })
      .addCase(completeRegistrationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(completeRegistrationAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.registration = true;
          state.registrationStep = 2;
          state.registrationError = "";
          state.first_name = payload.user.first_name;
          state.last_name = payload.user.last_name;
          state.email = payload.user.email;
          state.phone = payload.user.phone;
          state.token = payload.token;
          state.admin = payload.user.admin;
          state.dealer = payload.user.dealer;

          localStorage.setItem('token', payload.token);

          window.location.href = process.env.REACT_APP_BASE_URL; 
        } else {
          state.status = 'done';
          state.registrationError = payload.message;
        }
      })
      .addCase(dealerRegisterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(dealerRegisterAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.registration = false;
          state.registrationStep = 1;
          state.registrationError = "";
          state.first_name = payload.user.first_name;
          state.last_name = payload.user.last_name;
          state.email = payload.user.email;
          state.token = payload.token;
          state.admin = payload.user.admin;
          state.dealer = payload.user.dealer;
          state.dealerRegistration = false;

          localStorage.setItem('token', payload.token);
          window.location.href = process.env.REACT_APP_BASE_URL; 
        } else {
          state.status = 'done';
          state.registrationError = payload.message;
        }
      })
      .addCase(registerAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200 || payload.status === 201) {
          state.status = 'done';
          state.first_name = payload.user.first_name;
          state.last_name = payload.user.last_name;
          state.email = payload.user.email;
          state.phone = payload.user.phone;
          state.token = payload.token;
          state.admin = payload.user.admin;
          state.dealer = payload.user.dealer;
          state.registration = true;
          state.registrationStep = 2;
          state.registrationError = "";

          localStorage.setItem('token', payload.token);
        } else {
          state.status = 'done';
          state.registrationError = payload.message.errors;
        }
      })
      .addCase(registerAsync.rejected, (state, payload) => {
        state.status = 'error';
        state.registrationError = payload?.errorText;
      })
      .addCase(getCurrentUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentUserAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {

        } else {
          state.status = 'done';
        }
      })
      .addCase(vinLookupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(vinLookupAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.vehicleMake = payload.data.make;
          state.vehicleModel = payload.data.model;
          state.vehicleYear = payload.data.year
          state.vehicleVin = payload.data.vin;
          state.registrationError = '';
        } else {
          state.status = 'error';
          state.registrationError = payload.data.error;
          state.vehicleMake = '';
          state.vehicleModel = '';
          state.vehicleYear = '';
        }
      })
      .addCase(addVehicleAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addVehicleAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = "done";
          state.vehicleId = payload.data.attributes.id;
          state.vehicleMake = payload.data.attributes.make;
          state.vehicleModel = payload.data.attributes.model;
          state.vehicleYear = payload.data.attributes.year;
          state.vehicleVin = payload.data.attributes.vin;
          state.registration = true;
          state.registrationStep = 3;

        } else {
          state.status = 'error';
          state.registrationError = payload.message;
        }
      })
      .addCase(addVehicleAsync.rejected, (state, {payload}) => {
        state.state = 'error';
        state.registrationError = payload.errorText;
      })
      .addCase(updateVehicleAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateVehicleAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done'; 
          state.registration = false;
          state.registrationStep = 1;
          window.location.href = process.env.REACT_APP_BASE_URL;
        } else {
          state.status = 'error';
        }
      })
      .addCase(provisionVinAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(provisionVinAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.provisionPhoneNumber = '';
          state.provisionVin = '';
          // state.provisionedVins = payload.provisioned_vins.registrations;
          state.provisionVinMessage = "VIN provisioned for user successfully";
        } else {
          state.status = 'error';
          state.error = payload.message;
        }
      })
      .addCase(provisionVinAsync.rejected, (state, {payload}) => {
        state.state = 'error';
        state.error = payload;
      })
      .addCase(resendRegistrationSmsAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(resendRegistrationSmsAsync.fulfilled, (state, {payload}) => {
        if (payload.status === 200) {
          state.status = 'done';
          state.smsMessageSent = "SMS sent successfully";
        } else {
          state.status = 'error';
          state.error = payload.message;
        }
      })
      .addCase(getProvisionedVinsAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getProvisionedVinsAsync.fulfilled, (state, {payload}) => {
        state.status = 'done';
        state.provisionedVins = payload.data;
        state.provisionedVinsFilter = payload.meta.current_filter;
        state.provisionedVinsCurrentPage = payload.meta.current_page;
        state.provisionedVinsTotalPages = payload.meta.total_pages;
        state.provisionedVinsTotalEntries = payload.meta.total_entries;
      })
  }
})

export const id = (state) => state.account.id;
export const first_name = (state) => state.account.first_name;
export const last_name = (state) => state.account.last_name;
export const phone = (state) => state.account.phone;
export const email = (state) => state.account.email;
export const admin = (state) => state.account.admin;
export const dealer = (state) => state.account.dealer;

export const vehicleId = (state) => state.account.vehicleId;
export const vehicleMake = (state) => state.account.vehicleMake;
export const vehicleModel = (state) => state.account.vehicleModel;
export const vehicleYear = (state) => state.account.vehicleYear;
export const vehicleVin = (state) => state.account.vehicleVin;
export const vehicleUrl = (state) => state.account.vehicleUrl;

export const provisionedVins = (state) => state.account.provisionedVins;
export const provisionedVinsCurrentPage = (state) => state.account.provisionedVinsCurrentPage;
export const provisionedVinsTotalPages = (state) => state.account.provisionedVinsTotalPages;
export const provisionedVinsTotalEntries = (state) => state.account.provisionedVinsTotalEntries;
export const provisionedVinsFilter = (state) => state.account.provisionedVinsFilter;
export const provisionVin = (state) => state.account.provisionVin;
export const provisionPhoneNumber = (state) => state.account.provisionPhoneNumber;
export const provisionVinMessage = (state) => state.account.provisionVinMessage;
export const smsMessageSent = (state) => state.account.smsMessageSent;
export const passwordReset = (state) => state.account.passwordReset;
export const resetPasswordToken = (state) => state.account.resetPasswordToken;
export const resetPasswordMessage = (state) => state.account.resetPasswordMessage;

export const selectedVehicle = (state) => state.account.selectedVehicle;
export const getToken = (state) => state.account.token;
export const loginErrorResponse = (state) => state.account.loginError;
export const registrationErrorResponse = (state) => state.account.registrationError;
export const registration = (state) => state.account.registration;
export const getRegistrationStep = (state) => state.account.registrationStep;
export const getStatus = (state) => state.account.status;
// export const setDealerRegistration = (state) => state.account.dealerRegistration;
export const dealerRegistration = (state) => state.account.dealerRegistration;

export const {
  dismissLoginError,
  dismissRegistrationError,
  updateProvisionVin,
  updateProvisionPhoneNumber,
  updateVehicleVin,
  updateFirstName, 
  updateLastName, 
  updatePhone, 
  updateEmail, 
  logout, 
  logError, 
  register,
  setVehicleUrl,
  skipImageUpload,
  setRegistrationLevel,
  setDealerRegistration,
  clearMessages,
} = accountSlice.actions;

export default accountSlice.reducer;