import WarrantyServiceContractImage from "./WarrantyServiceContractImage";
import { useSelector } from "react-redux";

const openLearnMore = () => {
  window.open("https://trusted.sale/carrlly/warranty-options", "_blank");
}

function WarrantyServiceContract() {
  let expiration_date = "";
  let expiration_mileage = "";

  const contractDetailBody = () => {
    if (expiration_mileage !== "" && expiration_mileage !== "") {
      return (
        <div>
          <div className="record-page-body-column">
            <span className="record-page-body-column-header">
              {expiration_date} <br/>
            </span>
            <br/>
            <span>Warranty Period Expires</span>
          </div>
          <div className="record-page-body-column">
            <span className="record-page-body-column-header">
              {expiration_mileage ? expiration_mileage.toLocaleString() : expiration_mileage} <br/>
            </span>
            <br/>
            <span>
              Warranty Mileage Limit
            </span>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="record-page">
      <div className="record-page-header">
        <div className="record-page-header-text">
          Warranty
        </div>
        <div className="button-image-grey">
          <WarrantyServiceContractImage button-type="record-button-gear"/>
        </div>
      </div>
      <div className="record-page-body">
        {contractDetailBody()}
      </div>
      <div className="record-page-good-news">
        <div className="record-page-good-news-title">
          Good News!
        </div>
        <div className="record-page-good-news-sub-title">
          You're eligible to extend your warranty
        </div>
        <div className="record-page-good-news-body">
          Make sure your vehicle remains protected against costly future repairs. Select from protection and payment options that match your driving and budget needs.
        </div>
      </div>
      <div className="service-page-buttons">
        <button className="button-green" onClick={openLearnMore}>
          <span className="button-green-text">Learn More</span>
        </button>
      </div>
    </div>
  )
}

export default WarrantyServiceContract;