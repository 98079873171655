import { useDispatch, useSelector } from 'react-redux';
import { 
  setSelectedGloveBoxPage, 
  selectedGloveBoxPage,
} from './gloveBoxSlice';
import { postEvent } from 'src/services/events/eventApi';

import {
  getUser,
} from '../../services/login/loginSlice';

import VehicleWorth from './VehicleWorth';

function Glovebox({name}) {
  const dispatch = useDispatch();
  const selectedPage = useSelector(selectedGloveBoxPage);
  const user = useSelector(getUser);

  const showMyCarWorth = () => {
    if (user.creation_source === "sales") {
      const createdAt = new Date(user.created_at);
      const ninetyDaysFromCreatedAt = new Date(createdAt.setDate(createdAt.getDate() + 90));
      const today = new Date();

      return today > ninetyDaysFromCreatedAt;
    } else {
      return true;
    }
  }

  const handleSpecialsClick = () => {
    postEvent("CARRLLY_SPECIALS", user.email, localStorage.getItem('token'))
    window.open('https://www.beavertoyotacumming.com/service/service-parts-special-coupons/', '_blank');
  }

  const renderWhatsMyCarWorth = () => {
    if (showMyCarWorth()) {
      return (
        <div className="service-button" onClick={() => {dispatch(setSelectedGloveBoxPage("worth"))}}>
          <div className="button-text-black">
            What's My Car Worth?
          </div>
        </div>
      );
    }
  }

  const renderPage = () => {
    if (selectedPage === "menu") {
      return (
        <div className="records-actions">
          {renderWhatsMyCarWorth()}
          <div className="service-button" onClick={handleSpecialsClick}>
            <div className="button-text-black">
              Special Offers
            </div>
          </div>
          <div className="service-button" onClick={() => window.open('https://trusted.sale/carrlly/user-guide', '_blank')}>
            <div className="button-text-black">
              Carrlly User Guide
            </div>
          </div>
        </div>
      );
    } else if (selectedPage === "worth") {
      return <VehicleWorth />;
    } else {
      return (
        <div>in the else</div>
      )
    }
  }

  return (
    <div className="records">
      {renderPage()}
    </div>
  )
}

export default Glovebox;