function WarrantyServiceContractImage() {
  return (
    <svg width="37" height="43" viewBox="0 0 37 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.8811 9.78377C36.8811 9.42929 36.5266 9.1457 36.1721 9.1457C24.6868 9.1457 19.086 0.354484 19.086 0.283588C19.086 0.212691 19.0151 0.212691 19.0151 0.212691L18.9442 0.141794C18.9442 0.141794 18.8733 0.141794 18.8733 0.070897C18.8024 0.070897 18.8024 0 18.7315 0H18.4479C18.377 0 18.377 1.00362e-07 18.3061 0.070897H18.2352C18.1643 0.070897 18.1643 0.141794 18.0934 0.141794L18.0225 0.212691C17.8098 0.354485 12.209 9.1457 0.723656 9.1457C0.369171 9.1457 0.014687 9.42929 0.014687 9.78377C0.014687 10.0674 -1.19056 36.5828 18.2352 42.3963H18.6606C38.0863 36.6537 36.8811 10.0674 36.8811 9.78377ZM1.43262 10.5636C11.2164 10.28 16.7464 4.18292 18.4479 1.91422C20.2203 4.18292 25.6794 10.28 35.4631 10.5636C35.534 14.6048 34.9669 35.9447 18.4479 41.0493C1.9289 35.9447 1.36173 14.6048 1.43262 10.5636Z" fill="black"/>
      <path d="M27.6644 21.9071C27.6644 16.8026 23.5524 12.6905 18.4478 12.6905C13.3432 12.6905 9.2312 16.8026 9.2312 21.9071C9.2312 27.0117 13.3432 31.1237 18.4478 31.1237C23.5524 31.1237 27.6644 27.0117 27.6644 21.9071ZM10.6491 21.9071C10.6491 17.5824 14.1231 14.1085 18.4478 14.1085C22.7725 14.1085 26.2465 17.5824 26.2465 21.9071C26.2465 26.2318 22.7725 29.7058 18.4478 29.7058C14.1231 29.7058 10.6491 26.2318 10.6491 21.9071Z" fill="black"/>
      <path d="M23.0561 19.4257C22.7725 19.1422 22.3472 19.1422 22.0636 19.4257L16.8881 24.6012L14.5485 22.2616C14.2649 21.978 13.8395 21.978 13.556 22.2616C13.2724 22.5452 13.2724 22.9706 13.556 23.2542L16.3918 26.0901C16.5336 26.2319 16.7463 26.3027 16.8881 26.3027C17.0299 26.3027 17.2426 26.2319 17.3844 26.0901L23.0561 20.4183C23.3397 20.1347 23.3397 19.7093 23.0561 19.4257Z" fill="black"/>
    </svg>

  )
}

export default WarrantyServiceContractImage;