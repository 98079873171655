import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getOtpPassword(email) {
  const user_body = {
    "otp": {
      email
    }
  };

  try {
    const response = await axios.post(API_URL + "auth/send_otp", user_body);

    const response_obj = {
      status: response.data.status.code,
      message: response.data.status.message,
    }

    return response_obj;
  } catch (err) {
    const error_response = {
      status: err.response.status,
      message: err.response.data,
      statusText: err.response.statusText,
    }

    return error_response;
  }
}

export async function login(email, password) {
  const user_body = {
    "login": {
      email,
      password
    }
  };

  try {
    const response = await axios.post(API_URL + "auth/login", user_body);
    const response_obj = {
      status: response.status,
      token: response.headers.authorization,
      user: response.data.user,
      expiry: response.data.exp,
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}

export function logout() {
  localStorage.removeItem("user");
}
