import SimpleFileUpload from 'react-simple-file-upload'
import { Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy';

const RegistrationStepThree = ({
  handleVehicleFile,
  skipUpload
}) => {
  const key = process.env.REACT_APP_SIMPLE_FILE_UPLOAD_API;
  return (
    <div>
      {/* <Row as="div" className="video-wrapper"> */}
      <div className="video-wrapper">
        <ReactPlayer
          url="https://youtu.be/tw1jcacSDe4?si=QFubNLBvhvxNsDXy"
          // className="react-player"
          width='100%'
          height='100%'
          controls
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                rel: 0,
              }
            }
          }}
        />
      {/* </Row> */}
    </div>

      <div>
        Take a picture (or upload an existing one) of your vehicle.
      </div>
      <div>
        <SimpleFileUpload
          apiKey={key}
          onSuccess={handleVehicleFile}
          preview={false}
        />
      </div>
      <div>
        <a href="#" onClick={skipUpload}>Skip</a>
      </div>
    </div>
  );
}

export default RegistrationStepThree;