import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  uploadFile,
  getRegistrationUploadFiles,
  getUsers,
  getUser,
  updateUser,
  createUser,
} from './dealerApi';

export const createUserAsync = createAsyncThunk(
  'dealer/createUser',
  async (user) => {
    const response = await createUser(user);
    return response;
  }
);

export const updateUserAsync = createAsyncThunk(
  'dealer/updateUser',
  async (user) => {
    const response = await updateUser(user);
    return response;
  }
);

export const getUploadedFiles = createAsyncThunk(
  'dealer/getRegistrationUploadFiles',
  async (payload) => {
    const response = await getRegistrationUploadFiles(payload.page);
    return response;
  }
);

export const getUserAsync = createAsyncThunk(
  'dealer/getUser',
  async (payload) => {
    const response = await getUser(payload);
    return response;
  }
);

export const getUsersAsync = createAsyncThunk(
  'dealer/getUsers',
  async (payload) => {
    const response = await getUsers(payload.page, payload.per_page, payload.searchCriteria);
    return response;
  }
);

export const uploadRegistrationFile = createAsyncThunk(
  'dealer/uploadRegistration',
  async (data) => {
    const response = await uploadFile(data);
    return response;
  }
);

export const dealerSlice = createSlice({
  name: 'dealer',
  initialState: {
    uploadStatus: 'idle',
    uploadResponseMessage: '',
    uploadedFiles: [],
    uploadedFilesCurrentPage: 1,
    uploadedFilesTotalPages: 0,
    getUsersStatus: 'idle',
    getUsersResponseMessage: '',
    getUsersTotalPages: 0,
    saveUserMessage: {},
    userToEdit: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      admin: false,
      dealer: false,
      vehicles: [],
    },
    users: [],

  },
  reducers: {
    resetUploadStatus: (state) => {
      state.uploadStatus = 'idle';
    },
    resetSaveUserMessage: (state) => {
      state.saveUserMessage = {};
    },
    resetUserToEdit: (state) => {
      state.userToEdit = {};
    },
    updateEmail: (state, action) => {
      state.userToEdit.email = action.payload;
    },
    updateFirstName: (state, action) => {
      state.userToEdit.first_name = action.payload;
    },
    updateLastName: (state, action) => {
      state.userToEdit.last_name = action.payload;
    },
    updatePhone: (state, action) => {
      state.userToEdit.phone = action.payload;
    },
    updateDealer: (state, action) => {  
      state.userToEdit.dealer = action.payload;
    },
    updateAdmin: (state, action) => {
      state.userToEdit.admin = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadRegistrationFile.pending, (state) => {
        state.uploadStatus = 'loading';
      })
      .addCase(uploadRegistrationFile.fulfilled, (state, action) => {
        state.uploadStatus = 'succeeded';
        state.uploadResponseMessage = 'File successfully uploaded';
        state.uploadedFiles = action.payload;
      })
      .addCase(uploadRegistrationFile.rejected, (state, action) => {
        state.uploadStatus = 'failed';
        state.uploadResponseMessage = 'File failed to upload';
        state.uploadResponseMessage = action.error.message;
      })
      .addCase(getUploadedFiles.pending, (state) => {
        state.uploadStatus = 'loading';
      })
      .addCase(getUploadedFiles.fulfilled, (state, action) => {
        state.uploadStatus = 'succeeded';
        state.uploadedFiles = action.payload;

        state.uploadedFilesCurrentPage = action.payload.meta.current_page;
        state.uploadedFilesTotalPages = action.payload.meta.total_pages;
      })
      .addCase(getUploadedFiles.rejected, (state, action) => {
        state.uploadStatus = 'failed';
        state.uploadResponseMessage = action.error.message;
      })
      .addCase(getUsersAsync.pending, (state) => {
        state.getUsersStatus = 'loading';
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.getUsersStatus = 'succeeded';
        state.users = action.payload;
        state.getUsersTotalPages = action.payload.meta.total_pages;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.getUsersStatus = 'failed';
        state.getUsersResponseMessage = action.error.message;
      })
      .addCase(getUserAsync.pending, (state, action) => {
        state.getUsersStatus = 'loading';
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.getUsersStatus = 'succeeded';
        const user = {
          id: action.payload.data.id,
          email: action.payload.data.attributes.email,
          first_name: action.payload.data.attributes.first_name,
          last_name: action.payload.data.attributes.last_name,
          phone: action.payload.data.attributes.phone,
          admin: action.payload.data.attributes.admin,
          dealer: action.payload.data.attributes.dealer,
          vehicles: action.payload.data.attributes.vehicles,
        }

        state.userToEdit = user;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.getUsersStatus = 'loading';
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.getUsersStatus = 'succeeded';
        state.user_to_edit = action.payload;
        state.saveUserMessage = {
          status: 'success',
          message: 'User successfully updated'
        }
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.getUsersStatus = 'failed';
        state.getUsersResponseMessage = action.error.message;
        state.saveUserMessage = {
          status: 'failed',
          message: 'User failed to update'
        }
      })
      .addCase(createUserAsync.pending, (state) => {
        state.getUsersStatus = 'loading';
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.getUsersStatus = 'succeeded';
        state.saveUserMessage = {
          status: 'success',
          message: 'User successfully created'
        }
      })
  }
})

export const uploadedFiles = (state) => state.dealer.uploadedFiles;
export const uploadedFilesCurrentPage = (state) => state.dealer.uploadedFilesCurrentPage;
export const uploadedFilesTotalPages = (state) => state.dealer.uploadedFilesTotalPages;
export const users = (state) => state.dealer.users;
export const getUsersTotalPages = (state) => state.dealer.getUsersTotalPages;
export const userToEdit = (state) => state.dealer.userToEdit;
export const saveUserMessage = (state) => state.dealer.saveUserMessage;

export const { 
  resetUploadStatus,
  resetSaveUserMessage,
  resetUserToEdit,
  updateEmail,
  updateFirstName,
  updateLastName,
  updatePhone,
  updateDealer,
  updateAdmin,
} = dealerSlice.actions;

export default dealerSlice.reducer;