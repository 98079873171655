import vehicleServiceRecordsImage from "./vehicle-service-records.svg";

function VehicleServiceRecordsImage() {
  return (
    <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2465 5.24102L14.7628 0.754041C14.5253 0.514538 14.2427 0.324569 13.9313 0.195136C13.6199 0.0657033 13.286 -0.000621044 12.9488 4.38231e-06H2.55814C1.87968 4.38231e-06 1.22901 0.269718 0.749261 0.749811C0.269517 1.2299 0 1.88105 0 2.56V23.04C0 23.719 0.269517 24.3701 0.749261 24.8502C1.22901 25.3303 1.87968 25.6 2.55814 25.6H17.4419C18.1203 25.6 18.771 25.3303 19.2507 24.8502C19.7305 24.3701 20 23.719 20 23.04V7.05629C20.0006 6.71888 19.9343 6.3847 19.805 6.07311C19.6757 5.76151 19.4858 5.47869 19.2465 5.24102ZM18.2605 6.22779C18.3392 6.31404 18.405 6.41124 18.4558 6.51637H14.6512C14.3428 6.51637 14.047 6.39377 13.8289 6.17555C13.6109 5.95732 13.4884 5.66135 13.4884 5.35273V1.54531C13.5934 1.59621 13.6905 1.66206 13.7767 1.7408L18.2605 6.22779ZM17.4419 24.2036H2.55814C2.24975 24.2036 1.95399 24.081 1.73592 23.8628C1.51786 23.6446 1.39535 23.3486 1.39535 23.04V2.56C1.39535 2.25139 1.51786 1.95541 1.73592 1.73719C1.95399 1.51896 2.24975 1.39637 2.55814 1.39637H12.093V5.35273C12.093 6.03169 12.3625 6.68283 12.8423 7.16292C13.322 7.64302 13.9727 7.91273 14.6512 7.91273H18.6046V23.04C18.6046 23.3486 18.4821 23.6446 18.2641 23.8628C18.046 24.081 17.7502 24.2036 17.4419 24.2036Z" fill="#11041C"/>
      <path d="M4.66667 7.82222H9.11111C9.28792 7.82222 9.45749 7.7473 9.58252 7.61394C9.70754 7.48058 9.77778 7.29971 9.77778 7.11111C9.77778 6.92251 9.70754 6.74164 9.58252 6.60828C9.45749 6.47492 9.28792 6.4 9.11111 6.4H4.66667C4.48986 6.4 4.32029 6.47492 4.19526 6.60828C4.07024 6.74164 4 6.92251 4 7.11111C4 7.29971 4.07024 7.48058 4.19526 7.61394C4.32029 7.7473 4.48986 7.82222 4.66667 7.82222ZM15.3333 10.1926H4.66667C4.48986 10.1926 4.32029 10.2675 4.19526 10.4009C4.07024 10.5342 4 10.7151 4 10.9037C4 11.0923 4.07024 11.2732 4.19526 11.4065C4.32029 11.5399 4.48986 11.6148 4.66667 11.6148H15.3333C15.5101 11.6148 15.6797 11.5399 15.8047 11.4065C15.9298 11.2732 16 11.0923 16 10.9037C16 10.7151 15.9298 10.5342 15.8047 10.4009C15.6797 10.2675 15.5101 10.1926 15.3333 10.1926ZM15.3333 13.9852H4.66667C4.48986 13.9852 4.32029 14.0601 4.19526 14.1935C4.07024 14.3268 4 14.5077 4 14.6963C4 14.8849 4.07024 15.0658 4.19526 15.1991C4.32029 15.3325 4.48986 15.4074 4.66667 15.4074H15.3333C15.5101 15.4074 15.6797 15.3325 15.8047 15.1991C15.9298 15.0658 16 14.8849 16 14.6963C16 14.5077 15.9298 14.3268 15.8047 14.1935C15.6797 14.0601 15.5101 13.9852 15.3333 13.9852ZM15.3333 17.7778H4.66667C4.48986 17.7778 4.32029 17.8527 4.19526 17.9861C4.07024 18.1194 4 18.3003 4 18.4889C4 18.6775 4.07024 18.8584 4.19526 18.9917C4.32029 19.1251 4.48986 19.2 4.66667 19.2H15.3333C15.5101 19.2 15.6797 19.1251 15.8047 18.9917C15.9298 18.8584 16 18.6775 16 18.4889C16 18.3003 15.9298 18.1194 15.8047 17.9861C15.6797 17.8527 15.5101 17.7778 15.3333 17.7778Z" fill="#11041C"/>
    </svg>
  )
}

export default VehicleServiceRecordsImage;