import "./vehicle-details.scss"
import { useSelector } from "react-redux";
import Vehicle from "../../images/Vehicle";
import { getUser } from "../login/loginSlice";

function VehicleDetails() {
  const currentUser = useSelector(getUser);

  const make = currentUser?.vehicles[0]?.make;
  const model = currentUser?.vehicles[0]?.model;
  const year = currentUser?.vehicles[0]?.year;
  const vin = currentUser?.vehicles[0]?.vin;
  const url = currentUser?.vehicles[0]?.remote_url;

  return (
    <div className="vehicle-details">
      <div className="vehicle-details-header">
        Vehicle Details
      </div>
      <div className="vehicle-details-body">
        <div className="vehicle-details-body-image">
          <Vehicle url={url}/>
        </div>
        <div className="vehicle-details-body-text">
          <ul>
            <li>{model}</li>
            <li>{make} - {year}</li>
            <li>VIN: {vin}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default VehicleDetails;