import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { 
  getMetrics,
  getUserCreationMetrics,
 } from './metricAPI';

export const fetchMetrics = createAsyncThunk(
  'metrics/fetchMetrics', 
  async (payload) => {
  const response = await getMetrics(payload.token);
  return response;
});

export const fetchUserCreationMetrics = createAsyncThunk(
  'metrics/fetchUserCreationMetrics',
  async (payload) => {
    const response = await getUserCreationMetrics(payload.token);
    return response;
});

const getMetricTotal = (metric) => {
  let total = 0;
  metric.counts.forEach((count) => {
    total += count;
  });
  
  return total;
}

export const metricSlice = createSlice({
  name: 'metrics',
  initialState: {
    metrics: [],
    users_provisioned_dates: [],
    users_provisioned_counts: [],
    user_provisioned_metrics: [],
    carrlly_login_dates: [],
    carrlly_login_counts: [],
    carrlly_login_total: 0,
    manual_registration_date: [],
    manual_registration_counts: [],
    manual_registration_total: 0,
    call_service_and_repair_dates: [],
    call_service_and_repair_counts: [],
    call_service_and_repair_total: 0,
    meet_carrlly: 0,
    meet_carrlly_dates: [],
    meet_carrlly_counts: [],
    meet_carrlly_total: 0,
    carrlly_specials_dates: [],
    carrlly_specials_counts: [],
    carrlly_specials_total: 0,
    call_road_side_assistance_dates: [],
    call_road_side_assistance_counts: [],
    call_road_side_assistance_total: 0,
    schedule_service_dates: [],
    schedule_service_counts: [],
    schedule_service_total: 0,
    whats_my_car_worth_counts: [],
    whats_my_car_worth_dates: [],
    whats_my_car_worth_total: 0,
    status: 'idle',
  },
  reducers: {
    logout: (state) => {
      state.metrics = [];
      state.users_provisioned_counts = [];
      state.users_provisioned_dates = [];
      state.user_provisioned_metrics = [];
      state.carrlly_login_dates = [];
      state.carrlly_login_counts = [];
      state.carrlly_login_total = 0;
      state.manual_registration_dates = [];
      state.manual_registration_counts = [];
      state.manual_registration_total = 0;
      state.call_service_and_repair_dates = [];
      state.call_service_and_repair_counts = [];
      state.call_service_and_repair_total = 0;
      state.meet_carrlly = 0;
      state.meet_carrlly_dates = [];
      state.meet_carrlly_counts = [];
      state.meet_carrlly_total = 0;
      state.carrlly_specials_counts = 0;
      state.carrlly_specials_dates = [];
      state.carrlly_specials_total = 0;
      state.call_road_side_assistance_dates = [];
      state.call_road_side_assistance_counts = [];
      state.call_road_side_assistance_total = 0;
      state.schedule_service_dates = [];
      state.schedule_service_counts = [];
      state.schedule_service_total = 0;
      state.whats_my_car_worth_counts = [];
      state.whats_my_car_worth_dates = [];
      state.whats_my_car_worth_total = 0;
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetrics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const metrics = action.payload.data;

        metrics.forEach((metric) => {
          if (metric.event_name === "CARRLLY_LOGIN") {
            state.carrlly_login_dates = metric.dates;
            state.carrlly_login_counts = metric.counts;
            state.carrlly_login_total = getMetricTotal(metric);
          } else if (metric.event_name === "MANUAL_REGISTRATION") {
            state.manual_registration_dates = metric.dates;
            state.manual_registration_counts = metric.counts;
            state.manual_registration_total = getMetricTotal(metric);
          } else if (metric.event_name === "CALL_SERVICE_AND_REPAIR") {
            state.call_service_and_repair_dates = metric.dates;
            state.call_service_and_repair_counts = metric.counts;
            state.call_service_and_repair_total = getMetricTotal(metric);
          } else if (metric.event_name === "MEET_CARRLLY") {
            state.meet_carrlly_dates = metric.dates;
            state.meet_carrlly_counts = metric.counts;
            state.meet_carrlly_total = getMetricTotal(metric);
          } else if (metric.event_name === "CARRLLY_SPECIALS") {
            state.carrlly_specials_dates = metric.dates;
            state.carrlly_specials_counts = metric.counts;
            state.carrlly_specials_total = getMetricTotal(metric);
          } else if (metric.event_name === "CALL_ROAD_SIDE_ASSISTANCE") {
            state.call_road_side_assistance_dates = metric.dates;
            state.call_road_side_assistance_counts = metric.counts;
            state.call_road_side_assistance_total = getMetricTotal(metric);
          } else if (metric.event_name === "SCHEDULE_SERVICE") {
            state.schedule_service_dates = metric.dates;
            state.schedule_service_counts = metric.counts;
            state.schedule_service_total = getMetricTotal(metric);
          } else if (metric.event_name === "WHATS_MY_CAR_WORTH") {
            state.whats_my_car_worth_dates = metric.dates;
            state.whats_my_car_worth_counts = metric.counts;
            state.whats_my_car_worth_total = getMetricTotal(metric);
          }
        });
        state.metrics = action.payload;
      })
      .addCase(fetchMetrics.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchUserCreationMetrics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserCreationMetrics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const metrics = action.payload.data;
        state.user_provisioned_metrics = metrics;
      })
  },
});

export const getUsersProvisionedDates = (state) => state.metrics.users_provisioned_dates;
export const getUsersProvisionedCounts = (state) => state.metrics.users_provisioned_counts;
export const getUserProvisionedMetrics = (state) => state.metrics.user_provisioned_metrics;

export const getMeetCarrllyDates = (state) => state.metrics.meet_carrlly_dates;
export const getMeetCarrllyCounts = (state) => state.metrics.meet_carrlly_counts;
export const getMeetCarrllyTotal = (state) => state.metrics.meet_carrlly_total;

export const getCarrllyLoginDates = (state) => state.metrics.carrlly_login_dates;
export const getCarrllyLoginCounts = (state) => state.metrics.carrlly_login_counts;
export const getCarrllyLoginTotal = (state) => state.metrics.carrlly_login_total;

export const getManualRegistrationDates = (state) => state.metrics.manual_registration_dates;
export const getManualRegistrationCounts = (state) => state.metrics.manual_registration_counts;
export const getManualRegistrationTotal = (state) => state.metrics.manual_registration_total;

export const getCallServiceAndRepairDates = (state) => state.metrics.call_service_and_repair_dates;
export const getCallServiceAndRepairCounts = (state) => state.metrics.call_service_and_repair_counts;
export const getCallServiceAndRepairTotal = (state) => state.metrics.call_service_and_repair_total;

export const getCallRoadSideAssistanceCounts = (state) => state.metrics.call_road_side_assistance_counts;
export const getCallRoadSideAssistanceDates = (state) => state.metrics.call_road_side_assistance_dates;
export const getCallRoadSideAssistanceTotal = (state) => state.metrics.call_road_side_assistance_total;

export const getScheduleServiceCounts = (state) => state.metrics.schedule_service_counts;
export const getScheduleServiceDates = (state) => state.metrics.schedule_service_dates;
export const getScheduleServiceTotal = (state) => state.metrics.schedule_service_total;

export const getCarrllySpecialsCounts = (state) => state.metrics.carrlly_specials_counts;
export const getCarrllySpecialsDates = (state) => state.metrics.carrlly_specials_dates;
export const getCarrllySpecialsTotal = (state) => state.metrics.carrlly_specials_total;

export const getWhatsMyCarWorthCounts = (state) => state.metrics.whats_my_car_worth_counts;
export const getWhatsMyCarWorthDates = (state) => state.metrics.whats_my_car_worth_dates;
export const getWhatsMyCarWorthTotal = (state) => state.metrics.whats_my_car_worth_total;

export const { logout } = metricSlice.actions;

export default metricSlice.reducer;