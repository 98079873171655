import { NumericFormat } from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./worth.scss";

function Calculation({
  min_price_range, 
  max_price_range, 
  average_days_on_market,
  comparableVehiclesCount,
  returnToValuePage
}) {
  return (
    <div className="vehicle-worth">
      <div className="vehicle-worth-calculation-return">
        <a href="# " onClick={() => returnToValuePage(false)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp; Return to Value Page</a>
      </div>
      <div className="vehicle-worth-calculation-header">
        YOUR VEHICLE'S TRUSTED VALUE RANGE
      </div>

      <div className="vehicle-worth-calculation-range">
        <NumericFormat
          type="text" 
          value={min_price_range} 
          displayType={'text'} 
          prefix={'$'} 
          thousandsGroupStyle="lakh" 
          thousandSeparator="," 
        /> 
        - 
        <NumericFormat 
          type="text" 
          value={max_price_range} 
          displayType={'text'} 
          prefix={'$'} 
          thousandsGroupStyle="lakh" 
          thousandSeparator="," 
        />
      </div>

      <div className="vehicle-worth-calculation-calculated">
        <div className="vehicle-worth-calculation-calculated-header">
          How Was Your Value Calculated:
        </div>
        <div className="vehicle-worth-calculation-calculated-content">
          Using the most advanced retail automotive techno-wizadry, the 
          vehicle inventory experts at Vincue analyze real-time vehicle 
          inventory data across every major valuation platform and across 
          every local, regional, and national market to compile a trusted 
          value range that you can expect your vehicle to sell for.
        </div>
        <hr />
      </div>

      <div className="vehicle-worth-calculation-calculated-stop-looking">
        <div className="vehicle-worth-calculation-calculated-stop-looking-red">
          Stop searching sites for a better offer.
        </div>
        <div className="vehicle-worth-calculation-calculated-stop-looking-black">
          We already did all the heavy lifting for you!
        </div>
      </div>

      <div className="vehicle-worth-calculation-calculated-supply-and-days">
        <div className="vehicle-worth-calculation-calculated-supply">
          <div className="vehicle-worth-calculation-calculated-supply-label">
            SUPPLY
            <hr />
          </div>
          <div className="vehicle-worth-calculation-calculated-supply-value">
            {comparableVehiclesCount}
          </div>
          <div className="vehicle-worth-calculation-calculated-supply-content">
            The number of vehicles like yours for sale by area dealers
          </div>
        </div>
        <div className="vehicle-worth-calculation-calculated-days">
          <div className="vehicle-worth-calculation-calculated-days-label">
            DAYS TO SELL
            <hr />
          </div>
          <div className="vehicle-worth-calculation-calculated-days-value">
            {average_days_on_market}
          </div>
          <div className="vehicle-worth-calculation-calculated-days-content">
            Estimated time to sell your car on your own, based
            on comparable local lists.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calculation;