import { useSelector } from "react-redux";
import {
  registration,
} from './services/account/accountSlice';

import {
  getToken,
  getUser,
} from './services/login/loginSlice';

import App from './App';
import LoginWrapper from './components/login/LoginWrapper';
import ResetPassword from './ResetPassword';
import { DealerApp } from "./components/dealer/DealerApp";

function AppWrapper() {
  const token = useSelector(getToken);
  const reg = useSelector(registration);
  const currentUser = useSelector(getUser);
  
  const renderApp = () => {
    const url = new URL(window.location.href);
    const id = url.pathname.split('/').pop();

    if ((token && Object.keys(token).length > 0) && reg === false) {
      if (currentUser?.dealer === true) {
        return <DealerApp />
      } else {
        return <App />
      }
    } else if (id === "reset-password") {
      return <ResetPassword />
    } else {
      return <LoginWrapper />
    }
  }

  return (
    <div>
      {renderApp()}
    </div>
  )
}

export default AppWrapper;