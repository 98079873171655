import { useDispatch, useSelector } from 'react-redux';
import Logo from '../Logo';
import RegistrationStepOne from './RegistrationStepOne';
import RegistrationStepTwo from './RegistrationStepTwo';
import DealerRegistration from './DealerRegistration';
import Login from './Login';

import mixpanel from "mixpanel-browser";

import {
  registrationErrorResponse,
  register,
  registration,
  getRegistrationStep,
  vinLookupAsync,
  vehicleId,
  vehicleVin,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  first_name,
  addVehicleAsync,
  updateVehicleAsync,
  skipImageUpload,
  setVehicleUrl,
  setRegistrationLevel,
  setDealerRegistration,
  dealerRegisterAsync,
  dealerRegistration,
  dismissLoginError,
  dismissRegistrationError,
} from "../../services/account/accountSlice";

import "./login.scss";
import RegistrationStepThree from './RegistrationStepThree';
import { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

export default function LoginWrapper() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage'});
  const dispatch = useDispatch();

  // const loginError = useSelector(getLoginError);
  const registrationError = useSelector(registrationErrorResponse);
  const renderRegistration = useSelector(registration);
  const registrationStep = useSelector(getRegistrationStep);

  const vehicle_id = useSelector(vehicleId);
  const vin = useSelector(vehicleVin);
  const make = useSelector(vehicleMake);
  const model = useSelector(vehicleModel);
  const year = useSelector(vehicleYear);
  const user_first_name = useSelector(first_name);
  const dealerView = useSelector(dealerRegistration);

  // detect param id in url
  const url = new URL(window.location.href);
  const id =url.pathname.split('/').pop();

  useEffect(() => {
    if (id && id.length === 17 && id !== 'dealer') {
      dispatch(vinLookupAsync({vin: id}));
      dispatch(setRegistrationLevel());
    } else if (id && id === 'dealer') {
      dispatch(register(false));
      dispatch(setDealerRegistration(true));
    } else {
      dispatch(register(false));
      dispatch(setDealerRegistration(false));
    }
  }, [id]);

  const lookupVin = (event) => {
    const vin = event.target.value;

    if (vin.length === 17) {
      dispatch(vinLookupAsync({vin}))
    }
  }

  const handleDismissLoginError = () => {
    dispatch(dismissLoginError());
  }

  const handleDismissRegistrationError = () => {
    dispatch(dismissRegistrationError());
  }

  const handleVehicleFile = (url) => {
    const record_url = url;
    dispatch(updateVehicleAsync({vehicle_id, record_url}));
    dispatch(setVehicleUrl(record_url));
  }

  const handleRegistrationSubmit = (event) => {
    event.preventDefault();
    dispatch(addVehicleAsync({vin, make, model, year}))
  }

  const handleDealerRegistrationSubmit = (event) => {
    event.preventDefault();
    const email = event.target.elements.email.value;
    const first_name = event.target.elements.first_name.value;
    const last_name = event.target.elements.last_name.value;
    const password = event.target.elements.password.value;
    const dealer = true;

    dispatch(dealerRegisterAsync({email, first_name, last_name, password, dealer}))
  }

  // take user to the registration page
  const handleRegisterClick = (event) => {
    event.preventDefault();
    dispatch(register(true));
  }

  // take user to the login page
  const handleLoginLinkClick = (event) => {
    event.preventDefault();
    dispatch(register(false));
    dispatch(setDealerRegistration(false));
    window.location.href = process.env.REACT_APP_BASE_URL; 

  }

  const skipUpload = (event) => {
    // event.preventDefault();
    dispatch(skipImageUpload());
    
  }

  const renderComponent = () => {
    if (renderRegistration) {
      if (registrationStep === 1) {
        const params = new URLSearchParams(window.location.search);

        return (
          <RegistrationStepOne
            vin={id}
            phone={params.get('phone')}
            handleLoginLinkClick={handleLoginLinkClick} 
          />
        )
      } else if (registrationStep === 2) {
        return (
          <RegistrationStepTwo 
            errorMessage={errorMessage} 
            handleRegistrationSubmit={handleRegistrationSubmit} 
            lookupVin={lookupVin}
            user_first_name={user_first_name}
          />
        )
      } else if (registrationStep === 3) {
        return (
          <RegistrationStepThree
            handleVehicleFile={handleVehicleFile}
            skipUpload={skipUpload}
          />
        )
      }
    } else if (dealerView) {
      return (
        <DealerRegistration
          errorMessage={errorMessage}
          handleDealerRegistrationSubmit={handleDealerRegistrationSubmit}
          handleLoginLinkClick={handleLoginLinkClick} />
      )
    } else {
      return (
        <Login 
          handleRegisterClick={handleRegisterClick}
        />
      )
    }
  }

  const errorMessage = () => {
    if (registrationError) {
      return (
        <Alert variant="danger" onClose={handleDismissRegistrationError} dismissible>
          {registrationError}
        </Alert>
      )
    } else {
      return (<div></div>);
    }
  }

  return (
    <div className='logo-wrapper'>
      <div className='logo'>
        <Logo />
      </div>
      <div className='login-details'></div>
      <div className="login-body-container">
        {renderComponent()}
      </div>
    </div>
  )
}