import React, { useEffect } from "react";
import { Table, Container, Col, Row, Form, Dropdown, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  users,
  getUsersAsync,
  getUsersTotalPages,
} from "src/services/dealer/dealerSlice";
import moment from "moment";
import "./users.scss";
import CustomPagination from "src/components/CustomPagination";
import { debounce } from "lodash";
import RouteConstants from "../RouteConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Users = ({updateUrl, params}) => {
  const dispatch = useDispatch();
  const totalPages = useSelector(getUsersTotalPages);
  const allUsers = useSelector(users);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [selectedCreationSource, setSelectedCreationSource] = React.useState("");
  const [selectedCreatedAt, setSelectedCreatedAt] = React.useState("");
  const [searchCriteria, setSearchCriteria] = React.useState({email: "", creation_source: ""});
  const [email, setEmail] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [showDateSelector, setShowDateSelector] = React.useState(false);

  // initial page load
  useEffect(() => {
    if (params) {
      setCurrentPage(params.selectedPage);
      setSearchCriteria(params.criteria);
    } 

    // don't wait for state to change...
    const incoming_selected_page = params !== null ? params?.selectedPage : 1;
    const incoming_criteria = params !== null ? params?.criteria : {email: "", creation_source: ""};

    search(incoming_criteria, incoming_selected_page);
  }, []);

  const handleCloseDateSelector = () => setShowDateSelector(false);

  const handleApplyDateFilter = () => {
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";
    const criteria = {
      email: email,
      creation_source: selectedCreationSource,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    search(criteria, currentPage);
    setShowDateSelector(false);
  }

  const handleShowCloseDateSelector = () => {
    setShowDateSelector(true);
  }

  const handlePaginationClick = (selectedPage) => {
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email, 
      creation_source: selectedCreationSource, 
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    };
    
    setCurrentPage(selectedPage);
    setSearchCriteria(criteria);
    
    search(criteria, selectedPage);
  }

  const handleCreateUser = () => {
    updateUrl(RouteConstants.CREATE_USER);
  }

  const handleEditUser = (user) => {
    const user_to_edit = {
      id: user.id,
      email: user.attributes.email,
      first_name: user.attributes.first_name,
      last_name: user.attributes.last_name,
      phone: user.attributes.phone,
    }
    updateUrl(RouteConstants.EDIT_USER, {user: user_to_edit}, {selectedPage: currentPage, criteria: searchCriteria});
  }

  const updatePerPage = (e) => {
    const per_page = e.target.value;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";
    
    setPerPage(per_page);
    setSearchCriteria(
      {
        email: email, 
        creation_source: selectedCreationSource, 
        created_at: selectedCreatedAt,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      }
    );
    
    search(searchCriteria, currentPage, per_page);
  }

  const handleEmailSearch = debounce((e) => {
    const email = e.target.value;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email,
      creation_source: selectedCreationSource,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    setSearchCriteria(criteria);
    
    setEmail(email);
    search(criteria, currentPage)
  }, 500); // Adjust the debounce delay as needed (in milliseconds)

  const handleSourceFilter = (source) => {
    const creation_source = source;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email,
      creation_source: creation_source,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    setSelectedCreationSource(source);

    search(criteria, currentPage);
  }

  const search = (criteria, selectedPage, immediatePerPage) => {
    if (immediatePerPage) {
      dispatch(getUsersAsync({ page: selectedPage, per_page: immediatePerPage, searchCriteria: criteria }));
    } else {
      dispatch(getUsersAsync({ page: selectedPage, per_page: perPage, searchCriteria: criteria }));
    }
  }

  const translateSelectedCreationSource = (source) => {
    switch (source) {
      case "0":
        return "Service";
      case "1":
        return "Sales";
      case "2":
        return "Manual";
      default:
        return "All";
    }
  }

  const renderDate = (date) => {
    moment.locale('en');
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }

  const renderSelectedDates = () => {
    const formattedStart = moment(startDate).format('MM-DD-YYYY');
    const formattedEndDate = moment(endDate).format('MM-DD-YYYY');

    if (formattedStart === "Invalid date" || formattedEndDate === "Invalid date") {
      return "Date Filter";
    } else {
      return `${formattedStart} - ${formattedEndDate}`;
    }
  }
      

  const renderUsers = () => {
    if (typeof allUsers === 'undefined' || allUsers === null || allUsers.length === 0){
      return <p>No users</p>
    } else {
      return (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Admin?</th>
              <th>Dealer?</th>
              <th>Created At</th>
              <th>Creation Source</th>
            </tr>
          </thead>
          <tbody>
            {allUsers?.data?.map((user) => (
              <tr key={user.attributes.id}>
                <td><Button className="ml-auto" variant="link" onClick={() => (handleEditUser(user))}>{user.attributes.email}</Button></td>
                <td>{user.attributes.first_name}</td>
                <td>{user.attributes.last_name}</td>
                <td>{user.attributes.admin ? "Yes" : "No"}</td>
                <td>{user.attributes.dealer ? "Yes" : "No"}</td>
                <td>{renderDate(user.attributes.created_at)}</td>
                <td>{user.attributes.creation_source}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    }
  }

  return (
    <div>
      <Container className="navbar-margin">
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            <Button className="ml-auto" variant="primary" onClick={handleCreateUser}>Add User</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="d-flex custom-spacing">
              <Dropdown onSelect={handleSourceFilter}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Creation Source: {translateSelectedCreationSource(selectedCreationSource)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="">All</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Manual</Dropdown.Item>
                  <Dropdown.Item eventKey="1">Sales</Dropdown.Item>
                  <Dropdown.Item eventKey="0">Service</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="secondary" onClick={handleShowCloseDateSelector}>{renderSelectedDates()}</Button>
            </div>
          </Col>
          <Col>
            <Form.Control size="md" type="text" onChange={handleEmailSearch} placeholder="Search by Email" />
          </Col> 
        </Row>
        <Row className="mb-3">
          <Col>
            {renderUsers()}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="auto" className="mb-2 mr-1">
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{perPage} Per Page</Form.Label>
                <Form.Control as="select" onChange={updatePerPage}>
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="mb-2 mr-1">
            <CustomPagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePaginationClick} />
          </Col>
        </Row>
        <Modal show={showDateSelector} onHide={handleCloseDateSelector}>
          <Modal.Header closeButton>
            <Modal.Title>Date Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <label>Created From:</label>
                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                <label>Created To:</label>
                <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
              </Row>
              <Row>
                <Button variant="link" onClick={() => {setStartDate(""); setEndDate("")}}>Clear Dates</Button>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDateSelector}>
              Close
            </Button>
            <Button variant="primary" onClick={handleApplyDateFilter}>
              Apply Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  )
}

export default Users;