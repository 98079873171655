import "./Records.scss";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRecordPage, selectedRecordPage } from './recordSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import VehicleServiceRecordsImage from './VehicleServiceRecordsImage';
import WarrantyServiceContractImage from './WarrantyServiceContractImage';
import CertifiedVehicleProgramInfoImage from './CertifiedVehicleProgramImage';
import OnlineVehicleInformationImage from './OnlineVehicleInformationImage';
import EvBatteryHealthReportImage from './EvBatteryHealthReportImage';

import VehicleServiceRecords from './VehicleServiceRecords';
import CertifiedVehicleProgramInfo from './CertifiedVehicleProgramInfo';
import EvBatteryHealthReport from './EvBatteryHealthReport';
import OnlineVehicleInformation from './OnlineVehicleInformation';
import WarrantyServiceContract from './WarrantyServiceContract';

function Records() {
  const dispatch = useDispatch();
  const selectedPage = useSelector(selectedRecordPage);

  const renderPage = () => {
    if (selectedPage === "menu") {
      return (
        <div className="records-actions">
          <div className="service-button" onClick={() => {dispatch(setSelectedRecordPage("vehicle-service-records"))}}>
            <div className="button-image-white">
              <VehicleServiceRecordsImage button-type="service-button-vehicle-service-records-image"/>
            </div>
            <div className="button-text-black">
              Vehicle service records
            </div>
          </div>

          <div className="service-button" onClick={() => {dispatch(setSelectedRecordPage("warranty-service-contract"))}}>
            <div className="button-image-white">
              <WarrantyServiceContractImage />
            </div>
            <div className="button-text-black">
              Warranty service contract
            </div>
          </div>

          <div className="service-button" onClick={() => {dispatch(setSelectedRecordPage("certified-vehicle-program"))}}>
            <div className="button-image-white">
              <CertifiedVehicleProgramInfoImage />
            </div>
            <div className="button-text-black">
              Certified vehicle program info
            </div>
          </div>

          {/* <div className="service-button" onClick={() => {dispatch(setSelectedRecordPage("ev-battery-warranty"))}}>
            <div className="button-image-white">
              <EvBatteryHealthReportImage />
            </div>
            <div className="button-text-black">
              EV Battery health report
            </div>
          </div> */}
        </div>
      );
    } else if (selectedPage === "vehicle-service-records") {
      return <VehicleServiceRecords />
    } else if (selectedPage === "warranty-service-contract") {
      return <WarrantyServiceContract />
    } else if (selectedPage === "certified-vehicle-program") {
      return <CertifiedVehicleProgramInfo />
    } else if (selectedPage === "online-vehicle-info") {
      return <OnlineVehicleInformation />
    } else if (selectedPage === "ev-battery-warranty") {
      return <EvBatteryHealthReport />
    } else {
      return (
        <div>in the else</div>
      )
    }
  }

  return (
    <div className="records">
      {renderPage()}
    </div>
  )
}

export default Records;