import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  updatePasswordAsync, 
  updatePasswordSuccessMessage,
  updatePasswordErrorMessage,
  requestPasswordResetSuccessMessage,
  requestPasswordResetErrorMessage,
  dismissUpdatePasswordMessage,
} from './services/password/passwordSlice';
import Logo from './components/Logo';
import { 
  Button,
  Form,
  Alert,
  Stack,
  FloatingLabel,
  InputGroup
} from 'react-bootstrap';
import { set } from 'date-fns';

function ResetPassword() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState(''); // [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showMessage, setShowMessage] = useState(false); // [showResetMessage, setShowResetMessage] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false); // [showResetMessage, setShowResetMessage] = useState(true);
    const [validationErrors, setValidationErrors] = useState(''); // [showResetMessage, setShowResetMessage] = useState(true);
    // const [errorMessage, setErrorMessage] = useState(''); // [errorMessage, setErrorMessage] = useState('');
    const resetSuccessMessage = useSelector(updatePasswordSuccessMessage);
    const resetErrorMessage = useSelector(updatePasswordErrorMessage);

    const url = new URL(window.location.href);
    const resetPasswordToken = url.searchParams.get("reset_password_token");

    const handleCancel = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);
        setShowMessage(false);
        dispatch(dismissUpdatePasswordMessage());
        window.location.href = process.env.REACT_APP_BASE_URL;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updatePasswordAsync({email: email, password: password, password_confirmation: confirmPassword, reset_password_token: resetPasswordToken}));
    }

    const dismissPasswordResetMessage = () => {
      dispatch(dismissUpdatePasswordMessage());
      setShowMessage(false);
    }

    if (resetSuccessMessage && resetSuccessMessage.length > 0) {
      if (showMessage !== true) {
        setShowMessage(true);
      }
    }
    // }

    const handleLoginClick = (event) => {
      event.preventDefault();
      dispatch(dismissUpdatePasswordMessage());
      setShowErrorMessage(false);

      window.location.href = process.env.REACT_APP_BASE_URL;
    }

    return (
      <div className='logo-wrapper'>
        <div className='logo'>
          <Logo />
        </div>
        <div className='login-details'></div>
        <div className="login-body-container">
          <div className="login-main-page">
            <h3>Reset Password</h3>
            <Alert variant='danger' show={showErrorMessage}>
              {validationErrors}<br/>
              {resetErrorMessage}
            </Alert>
            <Alert key="primary" variant="primary" show={showMessage} onClose={dismissPasswordResetMessage} dismissible>
              {resetSuccessMessage}. Click <a href="#" onClick={handleLoginClick}>here</a> to login.
            </Alert>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <InputGroup hasValidation>
                  <FloatingLabel controlId="floatingInputEmail" label="Enter Email address">
                    <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} required />                  
                  </FloatingLabel>
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <InputGroup hasValidation>
                  <FloatingLabel controlId="floatingInputPassword" label="Enter Password">
                    <Form.Control type='password' placeholder='New Password' value={password} onChange={e => setPassword(e.target.value)} required />
                  </FloatingLabel>
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicConfirmPassword'>
                <InputGroup hasValidation>
                  <FloatingLabel controlId="floatingInputConfirmPassword" label="Confirm Password">
                    <Form.Control type='password' placeholder='Confirm Password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                  </FloatingLabel>
                </InputGroup>
              </Form.Group>
              <Stack direction="horizontal" gap={1}>
                <Button type="submit" value="Submit">Submit</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Stack>
            </Form>
          </div>
        </div>
      </div>
    );
}

export default ResetPassword;