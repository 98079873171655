import { useDispatch, useSelector } from "react-redux";
import { Alert, Container, FloatingLabel, Form, InputGroup, Button, Row } from "react-bootstrap";
import { useState } from "react";
import { registerAsync, registrationErrorResponse, dismissRegistrationError } from "src/services/account/accountSlice";

const RegistrationStepOne = ({
  vin,
  phone,
  handleLoginLinkClick
}) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState(''); // const [phone, setPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [terms_accepted, setTermsAccepted] = useState(false); // const [vin, setVin] = useSta

  const registrationError = useSelector(registrationErrorResponse);

  const dispatch = useDispatch();
  const handleRegistrationStepOneSubmit = (event) => {
    event.preventDefault();
    dispatch(registerAsync({
      vin: vin,
      email: email, 
      first_name: firstName, 
      last_name: lastName,
      phone: phoneNumber, 
      terms_accepted: terms_accepted
    }));
  }

  const dismissRegistrationErrorMessage = () => {
    dispatch(dismissRegistrationError());
  }

  const registerErrorMessage = () => {
    if (registrationError) {
      const errorString = Object.entries(registrationError).map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)} ${value}`).join(', ');
      return (
        <Alert variant="danger" onClose={dismissRegistrationErrorMessage} dismissible >
          {errorString}
        </Alert>
      )
    }
  }


  return (
    <Container>
      <Row>
        <div className="login-main-page">
          {registerErrorMessage()}
          {/* {errorMessage()} */}
          <Form onSubmit={handleRegistrationStepOneSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <InputGroup hasValidation>
                <FloatingLabel controlId="floatingInputEmail" label="Enter Email address">
                  <Form.Control type="email" placeholder='Enter email' onChange={e => setEmail(e.target.value)} required />
                </FloatingLabel>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <InputGroup hasValidation>
                <FloatingLabel controlId="floatingInputFirstName" label="Enter First Name">
                  <Form.Control type="text" placeholder='Enter First Name' onChange={e => setFirstName(e.target.value)} required />
                </FloatingLabel>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <InputGroup hasValidation>
                <FloatingLabel controlId="floatingInputLastName" label="Enter Last Name">
                  <Form.Control type="text" placeholder='Enter Last Name' onChange={e => setLastName(e.target.value)} required />
                </FloatingLabel>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastPhone">
              <InputGroup hasValidation>
                <FloatingLabel controlId="floatingInputPhone" label="Enter Phone Number">
                  <Form.Control type="text" placeholder='Enter Phone Number' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required />
                </FloatingLabel>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicTermsAccepted">
              <InputGroup hasValidation>
                <Form.Check
                  type="checkbox"
                  id="terms_accepted"
                  label=''
                  onChange={e => setTermsAccepted(e.target.value)} required/>
                  <a href="https://trusted.sale/terms" rel="noreferrer" target="_blank">Terms and Conditions</a>
              </InputGroup>
            </Form.Group>
            <Button type="submit" className="login-button">Sign Up</Button>
          </Form>

          <div>
            <button onClick={handleLoginLinkClick} className="btn btn-link">Back to login</button>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default RegistrationStepOne;