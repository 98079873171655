import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Button,
  Form,
  FloatingLabel
} from "react-bootstrap";

import { logout } from "src/services/login/loginSlice";
import { clearVincue } from "../../components/glovebox/gloveBoxSlice";
import { clearServiceRecords } from "../../services/service-record/serviceRecordSlice";
import { clearGloveBox } from "../../components/glovebox/gloveBoxSlice";

import { 
  logout as logoutAccount
} from "src/services/account/accountSlice";

import { updateAccountAsync } from "../../services/account/accountSlice";
import { getUser } from "../../services/login/loginSlice";

import "./account.scss"

function Account() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  
  const user_id = currentUser?.id;
  const [account_first_name, setAccountFirstName] = useState(currentUser?.first_name);
  const [account_last_name, setAccountLastName] = useState(currentUser?.last_name);
  const [account_phone, setAccountPhone] = useState(currentUser?.phone);
  const [account_email, setAccountEmail] = useState(currentUser?.email);

  const handleUpdateAccountSubmit = (event) => {
    event.preventDefault();

    dispatch(updateAccountAsync(
      {
        user_id,
        form_email: account_email,
        form_first_name: account_first_name,
        form_last_name: account_last_name,
        form_phone: account_phone,
      }
    ));
  }

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(clearServiceRecords())
    dispatch(clearGloveBox());
    dispatch(clearVincue());
    dispatch(logoutAccount());
    dispatch(logout());
  }

  const formUpdateFirstName = (event) => {
    setAccountFirstName(event.target.value);
  }

  const formUpdateLastName = (event) => {
    setAccountLastName(event.target.value);
  }

  const formUpdatePhone = (event) => {
    setAccountPhone(event.target.value);
  }

  const formUpdateEmail = (event) => {
    setAccountEmail(event.target.value);
  }

  return (
    <div className="account-page">
      <Container>
        <Row>
          <Form onSubmit={handleUpdateAccountSubmit}>
            <Form.Group className="mb-3 lg-3">
              <FloatingLabel controlId="floatingInput" label="First Name">
                <Form.Control type="text" placeholder="First Name" onChange={formUpdateFirstName} value={account_first_name} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 lg-3">
              <FloatingLabel controlId="floatingInput" label="Last Name">
                <Form.Control type="text" placeholder="Last Name" onChange={formUpdateLastName} value={account_last_name} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 lg-3">
              <FloatingLabel controlId="floatingInput" label="Mobile Phone">
                <Form.Control type="text" placeholder="Mobile Phone" onChange={formUpdatePhone} value={account_phone} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 lg-3">
              <FloatingLabel controlId="floatingInput" label="Email">
                <Form.Control type="text" placeholder="Email" onChange={formUpdateEmail} value={account_email} />
              </FloatingLabel>
            </Form.Group> 

            <div className="account-page-form-action">
              <Button variant="primary"  type="submit" onClick={handleUpdateAccountSubmit}>Save Changes</Button>
            </div>
          </Form>
        </Row>
        <hr/>
        <Row>
          <Button variant="secondary" onClick={handleLogout}>Logout</Button>
        </Row>
      </Container>
    </div>
  )
}

export default Account;