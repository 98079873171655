import evBatteryHealtherReport from "./ev-battery-health-report.svg";

function EvBatteryHealthReportImage() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.04688 30.0156H28.9531C30.6266 30.0156 32.0078 28.6344 32.0078 26.9609V11.0234C32.0078 9.35 30.6266 7.96875 28.9531 7.96875H28.0234V5.71094C28.0234 4.75469 27.2531 3.98438 26.2969 3.98438H23.6406C22.6844 3.98438 21.9141 4.75469 21.9141 5.71094V7.96875H12.0859V5.71094C12.0859 4.75469 11.3156 3.98438 10.3594 3.98438H7.70312C6.74687 3.98438 5.97656 4.75469 5.97656 5.71094V7.96875H5.04688C3.37344 7.96875 1.99219 9.35 1.99219 11.0234V26.9609C1.99219 28.6344 3.37344 30.0156 5.04688 30.0156ZM22.7109 5.71094C22.7109 5.20625 23.1359 4.78125 23.6406 4.78125H26.2969C26.8016 4.78125 27.2266 5.20625 27.2266 5.71094V7.96875H22.7109V5.71094ZM6.77344 5.71094C6.77344 5.20625 7.19844 4.78125 7.70312 4.78125H10.3594C10.8641 4.78125 11.2891 5.20625 11.2891 5.71094V7.96875H6.77344V5.71094ZM2.78906 11.0234C2.78906 9.775 3.79844 8.76562 5.04688 8.76562H28.9531C30.2016 8.76562 31.2109 9.775 31.2109 11.0234V26.9609C31.2109 28.2094 30.2016 29.2188 28.9531 29.2188H5.04688C3.79844 29.2188 2.78906 28.2094 2.78906 26.9609V11.0234Z" fill="#11041C"/>
      <path d="M8.63281 14.7422V16.3359C8.63281 16.5484 8.81875 16.7344 9.03125 16.7344C9.24375 16.7344 9.42969 16.5484 9.42969 16.3359V14.7422H11.0234C11.2359 14.7422 11.4219 14.5563 11.4219 14.3438C11.4219 14.1312 11.2359 13.9453 11.0234 13.9453H9.42969V12.3516C9.42969 12.1391 9.24375 11.9531 9.03125 11.9531C8.81875 11.9531 8.63281 12.1391 8.63281 12.3516V13.9453H7.03906C6.82656 13.9453 6.64062 14.1312 6.64062 14.3438C6.64062 14.5563 6.82656 14.7422 7.03906 14.7422H8.63281ZM26.9609 14.7422C27.1734 14.7422 27.3594 14.5563 27.3594 14.3438C27.3594 14.1312 27.1734 13.9453 26.9609 13.9453H22.9766C22.7641 13.9453 22.5781 14.1312 22.5781 14.3438C22.5781 14.5563 22.7641 14.7422 22.9766 14.7422H26.9609Z" fill="#11041C"/>
      <path d="M9.03125 18.0625C11.0766 18.0625 12.75 16.3891 12.75 14.3438C12.75 12.2984 11.0766 10.625 9.03125 10.625C6.98594 10.625 5.3125 12.2984 5.3125 14.3438C5.3125 16.3891 6.98594 18.0625 9.03125 18.0625ZM9.03125 11.4219C10.6516 11.4219 11.9531 12.7234 11.9531 14.3438C11.9531 15.9641 10.6516 17.2656 9.03125 17.2656C7.41094 17.2656 6.10938 15.9641 6.10938 14.3438C6.10938 12.7234 7.41094 11.4219 9.03125 11.4219ZM24.9688 18.0625C27.0141 18.0625 28.6875 16.3891 28.6875 14.3438C28.6875 12.2984 27.0141 10.625 24.9688 10.625C22.9234 10.625 21.25 12.2984 21.25 14.3438C21.25 16.3891 22.9234 18.0625 24.9688 18.0625ZM24.9688 11.4219C26.5891 11.4219 27.8906 12.7234 27.8906 14.3438C27.8906 15.9641 26.5891 17.2656 24.9688 17.2656C23.3484 17.2656 22.0469 15.9641 22.0469 14.3438C22.0469 12.7234 23.3484 11.4219 24.9688 11.4219Z" fill="#11041C"/>
    </svg>
  )
}

export default EvBatteryHealthReportImage;

