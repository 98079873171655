import { useSelector } from "react-redux";
import { useState } from "react";
import {
  vehicleData,
  vincueLoadingStatus,
} from "./gloveBoxSlice";

import { Loader } from "../Loader";

import "./worth.scss"
import WhatsMyCarWorth from "./WhatsMyCarWorth";
import Calculation from "./Calculation";
import { NumericFormat } from 'react-number-format';
import vincue_logo from '../../images/vincue.png';
import TrustedCarOffer from './TrustedCarOffer.svg';
import Dollar from './Dollar.svg';

function VehicleWorth() {
  const vehicleValueData = useSelector(vehicleData);
  const loading = useSelector(vincueLoadingStatus);

  const [showCalculationPage, setShowHowCalculationPage] = useState(false);

  const handleShowCalculationPage = (toggle) => {
    setShowHowCalculationPage(toggle);
  }

  const renderPage = () => {
    if ( loading === 'loading' ) {
      return (
        <div className="vehicle-worth-loader">
          <Loader />
        </div>
      )
    } else if (vehicleValueData && Object.keys(vehicleValueData).length > 0 && showCalculationPage) {
      const min_price_range = vehicleValueData.buy_min_price_range;
      const max_price_range = vehicleValueData.buy_max_price_range;
      const daysOnMarket = vehicleValueData.avgDaysOnMarket;
      const compVehiclesCount = vehicleValueData.ComparableVehicles.length;

      return (
        <Calculation 
          min_price_range={min_price_range} 
          max_price_range={max_price_range}
          average_days_on_market={daysOnMarket}
          comparableVehiclesCount={compVehiclesCount}
          returnToValuePage={handleShowCalculationPage}
        />
      )
    } else if (vehicleValueData && Object.keys(vehicleValueData).length > 0) {
      const min_price_range = vehicleValueData.buy_min_price_range;
      const max_price_range = vehicleValueData.buy_max_price_range;

      return (
        <div className="vehicle-worth">
          <div className="vehicle-worth-header">
            <div className="vehicle-worth-header-tag">
              <img src={TrustedCarOffer} alt="Trusted Car Offer" />
            </div>
            <div className="vehicle-worth-header-powered-by">
              Proudly powered by <img src={vincue_logo} alt="vincue" />
            </div>
          </div>

          <div className="vehicle-worth-value">
            <div className="vehicle-worth-value-bottom-line">
              WHAT'S THE BOTTOM LINE?
            </div>
            <div className="vehicle-worth-value-range">
              <NumericFormat 
                type="text" 
                value={min_price_range} 
                displayType={'text'} 
                prefix={'$'} 
                thousandsGroupStyle="lakh" 
                thousandSeparator="," /> 
              - 
              <NumericFormat 
                type="text" 
                value={max_price_range} 
                displayType={'text'} 
                prefix={'$'} 
                thousandsGroupStyle="lakh" 
                thousandSeparator="," />
            </div>
            <div className="vehicle-worth-value-calculated">
              <a href="# " onClick={() => handleShowCalculationPage(true)}>How Your Value was Calculated</a>
            </div>
          </div>


          <div className="vehicle-worth-value-eligibility">
            BONUS ELIGIBILITY ALERT
          </div>

          <div className="vehicle-worth-value-bonus">
            <div><img src={Dollar} alt="Dollar" className="vehicle-worth-value-bonus-dollar"/></div>
            <div className="vehicle-worth-value-bonus-pay">We'll Pay You $500 MORE</div>
          </div>
          
          <div className="vehicle-worth-value-bonus-text">
            Because your vehicle is eligible for our certified 
            pre-owned program, we can pay a $500 bonus.
            This offer expires on *******
          </div>

          <button className="vehicle-worth-value-bonus-cta">REDEEM $500 BONUS</button>


          <div className="vehicle-worth-value-terms">
            <a href="#">Terms & Conditions</a>
          </div>
        </div>
      )
    } else {
      return <WhatsMyCarWorth />
    }
  }

  return (
    renderPage()
  )
}

export default VehicleWorth;