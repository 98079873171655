import logo from "../images/carrlly.svg";


function Logo() {
  return (
    // <div className="logo">
    // <div>
      <img className="logo-image" src={logo} />
    // </div>
  )
}

export default Logo;