import CertifiedVehicleProgramInfoImage from "./CertifiedVehicleProgramImage";
import CircleCheck from "./CircleCheckImage";
import VehicleInfoImage from "./VehicleInfoImage";

function CertifiedVehicleProgramInfo() {
  return (
    <div className="record-page">
      <div className="record-page-header">
        <CertifiedVehicleProgramInfoImage />
        <div className="record-page-header-text">
          Certified Vehicle Program Info
        </div>
      </div>
      <div className="record-page-service-info-body">
        <VehicleInfoImage />
      </div>
      <div className="record-page-section-header">
        <div className="record-page-section-header-title">
          Avoid costly repairs
        </div>
        <div>
          Every trusted sale certified vehicle undergoes a comprehensive safety and performance inspection
        </div>
      </div>
      <div className="record-page-section-header"> 
        <div className="record-page-section-header-title">
          Easy claims, Happy drivers
        </div>
        <div>
          If something does go wrong, We have dedicated specialists to guide you through a simple claims process. You can track each step via mobile app. With 80.000 service centers nationwide. your vehicle can be repaired close to home
        </div>
      </div>
      <div className="record-page-certified-vehicle">
        <div className="record-page-section-header">
          <div className="record-page-section-header-additional-benefits">
            <div className="record-page-section-header-title-white">
              <CircleCheck title="Easy claims, Happy drivers"/> 
            </div>
            <div className="record-page-section-body-white">
              We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:
            </div>
          </div>
        </div>
        <div className="record-page-section-header">
          <div className="record-page-section-header-additional-benefits">
            <div className="record-page-section-header-title-white">
              <CircleCheck title="Rental Benefits"/> 
            </div>
            <div className="record-page-section-body-white">
              We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:
              We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertifiedVehicleProgramInfo;