import { useDispatch, useSelector } from "react-redux";
import { Pagination, Stack } from "react-bootstrap";
import { 
  getProvisionedVinsAsync,
  resendRegistrationSmsAsync,
  provisionedVins,
  provisionedVinsCurrentPage,
  provisionedVinsTotalPages,
  provisionedVinsTotalEntries,
  provisionedVinsFilter,
  getToken,
} from "../../services/account/accountSlice";
import { Table, Button } from "react-bootstrap";

// import "./provision-vins.scss"

export default function ProvisionVins() { 
  const dispatch = useDispatch();
  const vins = useSelector(provisionedVins);
  const currentPage = useSelector(provisionedVinsCurrentPage);
  const totalPages = useSelector(provisionedVinsTotalPages);
  const totalEntries = useSelector(provisionedVinsTotalEntries);
  const filter = useSelector(provisionedVinsFilter);
  const token = useSelector(getToken);

  const handlePaginationClick = (e) => {
    e.preventDefault();
    const page = e.target.text;
    dispatch(getProvisionedVinsAsync({filter: filter, page: page, token}));
  }

  const handleResendSms = (vin, phone) => {
    dispatch(resendRegistrationSmsAsync({vin, phone, token}));
  }

  const renderResendSms = (status, vin, phone) => {
    if (status === "pending") {
      return <Button variant="outline-secondary" onClick={() => handleResendSms(vin, phone)} size="sm">Resend SMS</Button>
    } else {
      return "NA"
    }
  }

  const renderVins = () => {
    let registrations = [];

    if (vins.length === 0) {
      registrations.push(
        <tr>
          <td colSpan="3">No Provisioned Vins</td>
        </tr>
      )
    } else {
      registrations = vins.map((vin) => {
        return (
          <tr key={vin.attributes.id}>
            <td>{vin.attributes.vin}</td>
            <td>{vin.attributes.phone}</td>
            <td>{vin.attributes.user.first_name}</td>
            <td>{vin.attributes.user.last_name}</td>
            <td>{vin.attributes.created_date}</td>
            <td>{(vin.attributes.state).toUpperCase()}</td>
            <td>{renderResendSms(vin.attributes.state, vin.attributes.vin, vin.attributes.phone)}</td>
          </tr>
        )
      });
    }

    return registrations;
  }

  let items = [];

  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item key={number} onClick={handlePaginationClick} active={number === currentPage}>
        {number}
      </Pagination.Item>,
    );
  }


  return (
    <>
      <Stack gap={1} direction="vertical">
        <div className="p-1">
          <Pagination size="sm">{items}</Pagination>
        </div>
        <div className="p-1">
          {totalEntries} Provisioned VINs
        </div>
      </Stack>
      {/* {totalEntries} Provisioned VINs
      <Pagination size="sm">{items}</Pagination> */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>VIN</th>
            <th>Phone</th>
            <th>Sales/Service Advisor First Name</th>
            <th>Sales/Service Advisor Last Name</th>
            <th>Registration Create Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderVins()}
        </tbody>
      </Table>
    </>
  );
}