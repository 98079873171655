import { ThreeCircles } from "react-loader-spinner";

export function Loader() {
  return (
    <ThreeCircles
      height="100"
      width="100"
      color="#0F75BC"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
  )
}
