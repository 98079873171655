import { 
  Container, 
  Form, 
  Row, 
  Stack,
  Button,
} from 'react-bootstrap';

import "./login.scss";

const DealerRegistration = ({errorMessage, handleDealerRegistrationSubmit, handleLoginLinkClick}) => {

  return (
    <Container className='login-main-page'>
      <Row>
        {errorMessage()}
      </Row>
      <Stack gap={3}>
        <h1>Dealer Registration</h1>
        <Form onSubmit={handleDealerRegistrationSubmit}>
          <Form.Group className="mb-3">
            <Form.Control id="email" type="email" placeholder="Enter email" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control id="first_name" type="text" placeholder="Enter First Name" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control id="last_name" type="text" placeholder="Enter Last Name" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control id="password" type="password" placeholder="Enter Password" />
          </Form.Group>          
          <Button variant="primary" type="submit" className="login-main-page-buttons">
            Sign Up
          </Button>
        </Form>
        <div className="register">
          <a href="#" onClick={handleLoginLinkClick}>Back to login</a>  
        </div>
      </Stack>
    </Container>
  )
}

export default DealerRegistration;