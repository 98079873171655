import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import {
  requestPasswordReset,
  updatePassword,
} from './passwordAPI';

const initialState = {
  status: 'idle',
  showPasswordReset: false,
  updatePasswordSuccessMessage: '',
  updatePasswordErrorMessage: '',
  requestPasswordResetSuccessMessage: '',
  requestPasswordResetErrorMessage: '',
};

export const requestPasswordResetAsync = createAsyncThunk(
  'password/requestPasswordReset',
  async (email) => {
    const response = await requestPasswordReset(email);
    return response;
  }
);

export const updatePasswordAsync = createAsyncThunk(
  'password/updatePassword',
  async ({email, password, password_confirmation, reset_password_token}) => {
    const response = await updatePassword(email, password, password_confirmation, reset_password_token);
    return response;
  }
);

export const passwordSlice = createSlice({
  name: 'password',
  initialState: {
    status: 'idle',
    updatePasswordSuccessMessage: '',
    updatePasswordErrorMessage: '',
    requestPasswordResetSuccessMessage: '',
    requestPasswordResetErrorMessage: '',
  },
  reducers: {
    dismissUpdatePasswordMessage: (state) => {
      state.updatePasswordSuccessMessage = '';
      state.updatePasswordErrorMessage = '';
    },
    dismissRequestPasswordResetMessage: (state) => {
      state.requestPasswordResetSuccessMessage = '';
      state.requestPasswordResetErrorMessage = '';
    },
    setShowPasswordReset: (state, action) => {
      state.showPasswordReset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload.message && action.payload.message.length > 0) {
          state.updatePasswordSuccessMessage = action.payload.message;
        } else if (action.payload.error_message && action.payload.error_message.length > 0) {
          state.updatePasswordErrorMessage = action.payload.error_message;
        }
      })
      .addCase(requestPasswordResetAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(requestPasswordResetAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.status === 200) {
          state.requestPasswordResetSuccessMessage = action.payload.message;
        } else {
          state.requestPasswordResetErrorMessage = action.payload.message;
        }
      })
  }
});

export const updatePasswordSuccessMessage = (state) => state.password.updatePasswordSuccessMessage;
export const updatePasswordErrorMessage = (state) => state.password.updatePasswordErrorMessage;
export const requestPasswordResetSuccessMessage = (state) => state.password.requestPasswordResetSuccessMessage;
export const requestPasswordResetErrorMessage = (state) => state.password.requestPasswordResetErrorMessage;
export const showPasswordReset = (state) => state.password.showPasswordReset;

export const {
  dismissUpdatePasswordMessage,
  dismissRequestPasswordResetMessage,
  setShowPasswordReset,
} = passwordSlice.actions;

export default passwordSlice.reducer;