import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getUserCreationMetrics(token) {
  
  try {
    const url = `${API_URL}users/users_created_over_time`;

    let response = await axios.get(url, { headers: {"Authorization": token}});
  
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.resposne.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.resposne.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  } 
}

export async function getMetrics(token) {
  try {
    const url = `${API_URL}metrics`;

    let response = await axios.get(url, { headers: {"Authorization": token}});
    
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.resposne.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.resposne.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  } 
}