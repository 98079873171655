import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
  getVehicleTrims,
  callVincue,
 } from "../../services/glovebox/gloveboxAPI";

export const getVincueData = createAsyncThunk(
  `glovebox/getVincueData`,
  async (payload) => {
    const response = await callVincue(payload);
    return response;
  }
)

export const fetchVehicleTrims = createAsyncThunk(
  'glovebox/fetchVehicleTrims',
  async (payload) => {
    const response = await getVehicleTrims(payload);
    return response;
  }
)

const initialState = {
  selectedGloveBoxPage: "menu",
  vehicleData: {},
  vehicleTrims: [],
  selectedVehicleTrim: '',
  status: 'idle',
}  

export const gloveBoxSlice = createSlice({
  name: 'glovebox',
  initialState: initialState,
  reducers: {
    setSelectedGloveBoxPage: (state, action) => {
      state.selectedGloveBoxPage = action.payload;
    },
    clearGloveBox: (state) => {
      state.status = 'idle';
      state.vehicleData = {};
      state.vehicleTrims = [];
      state.selectedGloveBoxPage = "menu";
      state.selectedVehicleTrim = '';
    },
    clearVincue: (state) => {
      state.status = 'idle';
      state.vehicleData = {};
      state.vehicleTrims = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVincueData.pending, (state) => {
        state.status = 'loading';
        state.vehicleData = {};
        state.vehicleTrims = [];
      })
      .addCase(getVincueData.fulfilled, (state, {payload}) => {
        if (payload.status.code === 200) {
          state.status = 'done';

          // under some circumstances we will receive an array of trims
          // from the api call, we want to ask the user to select a trim.
          // Otherwise, present the vehicle worth page
          if (Array.isArray(payload.data)) {
            state.vehicleTrims = payload.data;
            state.vehicleData = {};
          } else {
            state.vehicleTrims = [];
            state.vehicleData = payload.data;
          }
        } else {
          state.status = 'error';
        }
      })
      .addCase(getVincueData.rejected, (state, {payload}) => {
        state.status = 'error';
        state.error = payload;
      })
  }
});

export const selectedGloveBoxPage = (state) => state.glovebox.selectedGloveBoxPage;
export const vehicleTrims = (state) => state.glovebox.vehicleTrims;
export const vehicleData = (state) => state.glovebox.vehicleData;
export const vincueLoadingStatus = (state) => state.glovebox.status;
// export const showHowCalculationPage = (state) => state.glovebox.showHowCalculationPage;

export const { 
  setSelectedGloveBoxPage,
  clearGloveBox,
  clearVincue,
  setShowHowCalculationPage,
} = gloveBoxSlice.actions;

export default gloveBoxSlice.reducer;