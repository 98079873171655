// import logo from "./TrustedCarFile.png";
import { useSelector } from "react-redux";
import Gear from "../../images/Gear";
import Schedule from "../../images/Schedule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { postEvent } from "../../services/events/eventApi";
import { getUser } from "src/services/login/loginSlice";

import {
  first_name,
  last_name,
  phone,
  email,
  vehicleMake,
  vehicleModel,
  vehicleVin,
  vehicleYear,
} from "../../services/account/accountSlice";

function Service() {
  const vin = useSelector(vehicleVin);
  const make = useSelector(vehicleMake);
  const model = useSelector(vehicleModel);
  const year = useSelector(vehicleYear);
  const firstname = useSelector(first_name);
  const lastname = useSelector(last_name);
  const customerPhone = useSelector(phone);
  const customerEmail = useSelector(email);
  const currentUser = useSelector(getUser);

  const callIt = () => {
    postEvent("CALL_SERVICE_AND_REPAIR", currentUser.email ,localStorage.getItem('token'));
    window.location = "tel:229-929-4357";
  }

  const mailIt = () => {
    let body = `My name is ${firstname} ${lastname}. Please contact me back asap regarding my vehicle ${vin} which is in need of service/repair. You can reach me at ${customerPhone} or at ${customerEmail}`;
    
    const subject = "Vehicle Service Request - " + year + " " + make + " " + model;
                    
    window.location = "mailto:TCF@beaverautomotive.com?subject=" + subject + "&body=" + body;
  }

  return (
    <div className="service-page">
      <div className="service-page-header">
        <div className="service-page-header-text">
          Help me with my vehicle's service or repair issue.
        </div>
        <div className="button-image-grey">
          <Gear button-type="service-button-gear"/>
        </div>
      </div>
      <div className="service-page-body">
        We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you. If you contact us after our regular business hours, we will get back to you as early as possible the next business day.
      </div>
      <div className="service-page-sub-info">
        <Schedule />
        <div className="service-page-sub-info-text">
          Monday - Saturday 8AM - 7PM EST
        </div>
      </div>
      <div className="service-page-buttons">
        <button className="service-page-buttons-button-red" onClick={callIt}>
          <svg width="23" height="40" viewBox="0 0 23 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.72521 1.73233L14.7262 5.19698L13.3958 14.4305L8.34599 14.5155C8.07178 18.7009 9.18617 22.8599 11.5164 26.3475L15.9321 23.8961L21.701 31.2274L18.2364 37.2284C17.7769 38.0242 17.0202 38.6048 16.1326 38.8427C15.245 39.0805 14.2993 38.956 13.5035 38.4965C7.85683 34.7622 3.77204 29.0904 2.01988 22.5513C0.267723 16.0122 0.969358 9.05784 3.9924 3.00048C4.45185 2.2047 5.20859 1.62403 6.09616 1.38621C6.98374 1.14838 7.92943 1.27289 8.72521 1.73233Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <span className="button-green">
            {/* <a href="tel:229-929-4357" className="service-page-buttons-button-text">Call us now</a> */}
            Call us now
          </span>
        </button>
        <button className="button-grey" onClick={mailIt}>
          <FontAwesomeIcon icon={faEnvelope} />
          <span className="button-grey-text">
            Send us a message
          </span>
        </button>
      </div>
      <div className="service-page-footer">
        * Message and data rates may apply. (Message frequency). Text HELP to ##### for help. Text STOP to ##### to cancel. For terms: (URL to SMS terms of service). For privacy: (URL to privacy policy)
      </div>
    </div>
  )
}

export default Service;