import vehicleInfo from "../../images/vehicle-info.png";

function VehicleInfoImage() {
  return (
    <div>
      <img src={vehicleInfo} className="record-page-service-info-image" />
    </div>
  )
}

export default VehicleInfoImage;