import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { 
  Row,
  Form,
  Modal,
  Button,
 } from 'react-bootstrap';

import WheresMyVin from "../../images/wheres-my-vin";
import "./login.scss";

import {
  vehicleMake,
  vehicleModel,
  vehicleVin,
  vehicleYear,
  updateVehicleVin,
  vinLookupAsync,
} from "../../services/account/accountSlice";

const RegistrationStepTwo = (
  { 
    errorMessage,
    handleRegistrationSubmit,
    lookupVin,
    user_first_name,
  }
) => {

  const dispatch = useDispatch();

  const vin = useSelector(vehicleVin);
  const year = useSelector(vehicleYear);
  const make = useSelector(vehicleMake);
  const model = useSelector(vehicleModel);

  const [show, setShow] = useState(false);
  const [incomingVin] = useState(vin);

  const handleClose = () => {
    dispatch(updateVehicleVin(incomingVin));
    dispatch(vinLookupAsync({vin: incomingVin}));
    setShow(false);
  }

  const handleSave = () => {
    dispatch(updateVehicleVin(vin));
    dispatch(vinLookupAsync({vin}));
    setShow(false);
  }

  const handleShow = () => setShow(true);

  const handleVinUpdate = (e) => {
    e.preventDefault();
    const vin = e.target.value;
    dispatch(updateVehicleVin(vin));

    if (vin.length === 17) {
      dispatch(vinLookupAsync({vin}))
    }
  }

  const renderVehicleInfo = () => {
    if (year && make && model) {
      return (
        <Row className="vehicle-info-wrapper">
          <ul style={{listStyle: 'none'}}>
            <li>Year: {year}</li>
            <li>Make: {make}</li>
            <li>Model: {model}</li>
          </ul>
        </Row>
      )
    }
  }

  const renderMessage = () => {
    if (incomingVin && incomingVin.length > 0) {
      return (
        <>
          <Row>
            <h3>PLEASE CONFIRM YOUR VEHICLE</h3>
          </Row>
          <Row>
            We've decoded your vehicle's year, make, and model:

            <ul style={{listStyle: 'none'}}>
              <li>VIN: {vin}</li>
              <li>Year: {year}</li>
              <li>Make: {make}</li>
              <li>Model: {model}</li>
            </ul>
          </Row>
          <Row>
            Please click/tap on "Add Vehicle" to complete your account registration.
          </Row>
        </>
      )
    } else {
      return (
        <p>
          Thanks for registering {user_first_name}. Now let's get started by adding your vehicle. 
          Scan or type your VIN below. Check that it is your vehicle and select "Add Vehicle".
        </p>
      )
    }
  }

  const renderPreRegistrationScanVehicle = () => {
    return (
      <>
        <a href="# " onClick={handleShow}>Not your vehicle?</a>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Not your vehicle?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage()}
            <Form.Label htmlFor="vin">Enter their Vehicle Identification Number ("VIN")</Form.Label>
            <Form.Control type="text" id="vin" name="vin" value={vin} onChange={handleVinUpdate} placeholder="Enter VIN" /> 
            {renderVehicleInfo()}
            {WheresMyVin()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  const renderManualScanVehicle = () => {
    return (
      <>
        <div>
          {/* {errorMessage()} */}
          <Form.Label htmlFor="vin">Enter their Vehicle Identification Number ("VIN")</Form.Label>
          <Form.Control type="text" id="vin" name="vin" value={vin} onChange={handleVinUpdate} placeholder="Enter VIN" /> 
          {renderVehicleInfo()}
          {WheresMyVin()}
        </div>
      </>
    )
  }

  const renderScanVehicle = () => {

    if (incomingVin && incomingVin.length > 0) {
      return (
        <Row>
        {renderPreRegistrationScanVehicle()}
        </Row>
      )
    } else {
      return (
        <Row>
        {renderManualScanVehicle()}
        </Row>
      )
    }
  }

const renderPage = () => {
  if (incomingVin && incomingVin.length > 0) {
    return (
      <div className="login-main-page">
        {errorMessage()}
        {renderMessage()}
        <Form onSubmit={handleRegistrationSubmit}>
          <div className="login-main-page-buttons">
            <button type="submit" className="login-button">Add Vehicle</button>
          </div>
          {renderScanVehicle()}
        </Form>
      </div>
    )
  } else {
    return (
      <div className="login-main-page">
        {errorMessage()}
        {renderMessage()}
        <Form onSubmit={handleRegistrationSubmit}>
          {renderScanVehicle()}
          <div className="login-main-page-buttons">
            <button type="submit" className="login-button">Add Vehicle</button>
          </div>        
        </Form>
      </div>
    )
  }
}

  return (
    <>
      {renderPage()}
    </>
  )
}

export default RegistrationStepTwo;