import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, getOtpPassword } from './loginAPI';
import { postEvent } from '../events/eventApi';

export const loginAsync = createAsyncThunk(
  'login/loginAsync',
  async (user, thunkAPI) => {
    const response = await login(user.email, user.password);

    return response;
  }
);

export const getOtpPasswordAsync = createAsyncThunk(
  'login/getOtpPasswordAsync',
  async (email, thunkAPI) => {
    const response = await getOtpPassword(email);
    return response;
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: localStorage.getItem('user'),
    token: localStorage.getItem('token') || '',
    otp: '',
    otpSent: false,
    loginEmail: '',
    emailLookupError: '',
    otpCompareError: '',
    registration: false,
    dealer: false,
  },
  reducers: {
    logout: (state) => {
      state.user = {};
      state.token = '';
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
    clearEmailLookupError: (state) => {
      state.emailLookupError = '';
    },
    clearOtpCompareError: (state) => {
      state.otpCompareError = '';
    },
    setLoginEmail: (state, action) => {
      state.loginEmail = action.payload;
    },
    clearOtpSent: (state) => {
      state.otpSent = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action) => {

        if (action.payload.status === 401) {
          state.otpCompareError = 'Invalid passcode. Please try again.'
        } else {
          state.user = action.payload.user;
          state.token = action.payload.token;
          state.emailLookupError = '';
          state.otpCompareError = '';
          state.loginEmail = '';
          state.otpSent = false;
          localStorage.setItem('token', action.payload.token);
          postEvent('CARRLLY_LOGIN', action.payload.user.email, action.payload.token);
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loginError = action.payload;
      })
      .addCase(getOtpPasswordAsync.fulfilled, (state, action) => {
        state.otpSent = true;
        state.loginError = '';
        state.emailLookupError = '';
        if (action.payload.status === 401) {
          state.otpSent = false;
          state.emailLookupError = 'Invalid email address. Please try again.'
        }
      })
      .addCase(getOtpPasswordAsync.rejected, (state, action) => {
        state.loginError = action.payload;
        state.otpSent = false;
      })
  },
});

export const {
  logout,
  clearEmailLookupError,
  clearOtpCompareError,
  setLoginEmail,
  clearOtpSent,
} = loginSlice.actions;

export const loginOtp = (state) => state.login.otp;
export const getLoginEmail = (state) => state.login.loginEmail;
export const getEmailLookupError = (state) => state.login.emailLookupError;
export const getOtpCompareError = (state) => state.login.otpCompareError;
export const getToken = (state) => state.login.token;
export const getUser = (state) => state.login.user;
export const getOtpSent = (state) => state.login.otpSent;
export default loginSlice.reducer;