import schedule from "./schedule.svg";


function Schedule() {
  return (
    <div className="service-button-locked-car">
      <img className="service-button-locked-car-image" src={schedule} />
    </div>
  )
}

export default Schedule;