import React from 'react';
import { useState } from 'react';
import { Alert, Container, Row, Stack, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { 
  loginAsync, 
  getOtpPasswordAsync,
  getEmailLookupError,
  getOtpCompareError,
  clearEmailLookupError,
  clearOtpCompareError,
  setLoginEmail,
  getLoginEmail,
  getOtpSent,
  clearOtpSent
} from 'src/services/login/loginSlice';

const Login = ({handleRegisterClick}) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const emailLookupError = useSelector(getEmailLookupError);
  const loginEmail = useSelector(getLoginEmail);
  const otpSent = useSelector(getOtpSent);
  const otpCompareError = useSelector(getOtpCompareError);
  
  const [login_password, setLoginPassword] = useState('');

  const dismissErrorMessage = () => {
    dispatch(clearEmailLookupError());
    dispatch(clearOtpCompareError());
  }

  const dismissOtpMessage = () => {
    dispatch(clearOtpSent());
  }

  const dismissEmailValidationError = () => {
    setMessage('');
  }

  const handleLoginEmailChange = (event) => {
    event.preventDefault();
    dispatch(setLoginEmail(event.target.value));
  }

  const handleLoginPasswordChange = (event) => {
    event.preventDefault();
    setLoginPassword(event.target.value);
  }

  const requestOneTimePassword = () => {
    dispatch(getOtpPasswordAsync(loginEmail));
  }

  const login = (event) => {
    event.preventDefault();
    const email = loginEmail;
    const password = login_password;
    dispatch(loginAsync({email, password}));
  }

  const renderLookupError = () => {
    if (emailLookupError.length > 0) {
      return (
        <Alert variant="danger" onClose={dismissErrorMessage} dismissible>
          {emailLookupError}
        </Alert>
      )
    } else {
      return (<div></div>);
    }
  }

  const renderOtpMessage = () => {
    if (otpSent) {
      return (
        <Alert variant="info" onClose={dismissOtpMessage} dismissible>
          We sent your one-time password to you. If you haven't received a text message with your code, please check your email.
        </Alert>
      )
    } else {
      return (<div></div>);
    }
  }

  const renderOtpErrorMessage = () => {
    if (otpCompareError.length > 0) {
      return (
        <Alert variant="danger" onClose={dismissErrorMessage} dismissible>
          {otpCompareError}
        </Alert>
      )
    } else {
      return (<div></div>);
    }
  }

  const renderErrorMessage = () => {
    if (message.length > 0) {
      return (
        <Alert variant="danger" onClose={dismissEmailValidationError} dismissible>
          {message}
        </Alert>
      )
    } else {
      return (<div></div>);
    }
  }

  const validateEmail = () => {

    const emailInput = document.getElementById('login-email');

    if (validator.isEmail(loginEmail)) {
      dispatch(requestOneTimePassword(loginEmail));
    } else {
      if (loginEmail.length === 0) {
        emailInput.focus();
      } else {
        setMessage("Please, enter valid Email!");
        emailInput.focus();
      }
    }
  }

  const renderFormButton = () => {
    if (loginEmail && loginEmail?.length >= 0 && emailLookupError?.length === 0) {
      return (
        <Button variant="primary" type="submit" onClick={login}>Login</Button>
      )
    } else {
      return (
        <Button variant="primary" type="submit" onClick={login} disabled>Login</Button>
      )
    }
  }

  return (
    <>
      <div className="login-main-page">
        <Container>
          {renderLookupError()}
          {renderErrorMessage()}
          {renderOtpErrorMessage()}
          <Stack direction="vertical" gap={3}>
            Enter your email address below and we'll send you a one-time use password to login.

            <Form>
              <div className="input-group mb-3">
                <Form.Control id="login-email" type="email" placeholder="Enter email" value={loginEmail} onChange={handleLoginEmailChange} />
                <div className="input-group-append">
                <Button onClick={validateEmail}>Send Code</Button>
                </div>
              </div>

              {renderOtpMessage()}
              <Form.Group className='mb-3 lg-3'>
                <Form.Control type="password" placeholder="Password" value={login_password} onChange={handleLoginPasswordChange} />
              </Form.Group>
              {renderFormButton()}
            </Form>
        </Stack>

          <Row>
            <Stack direction="vertical" gap={1}>
              <a href="# " onClick={handleRegisterClick}>Not Registered? Click Here</a>  
            </Stack>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Login;