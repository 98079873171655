// import React from 'react';
import SimpleFileUpload from 'react-simple-file-upload'
import './modal.scss';

function Modal({ onClose, handleVehicleFile}) {
  const key = process.env.REACT_APP_SIMPLE_FILE_UPLOAD_API;
  return (
    <div className="vehicle-modal">
      <div className="vehicle-modal-content">
        <button className="vehicle-modal-close-button" onClick={onClose}>
          X
        </button>
        <h1>Upload Image</h1>
        <div>
          <p>Select an image for this vehicle.</p>
          <div>
            <SimpleFileUpload
              apiKey={key}
              onSuccess={handleVehicleFile}
              preview={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
