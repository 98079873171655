import React, { useEffect } from 'react';
import axios from 'axios';
import { 
  Button, 
  Col, 
  Container, 
  Dropdown, 
  Form, 
  Modal,
  Row,
  Table
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import CustomPagination from 'src/components/CustomPagination';

import { 
  getEventsAsync,
  getEventsTotalPages,
  events,
} from 'src/services/events/eventSlice';

const Events = ({params}) => {
  const dispatch = useDispatch();
  const totalPages = useSelector(getEventsTotalPages);
  const allEvents = useSelector(events);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [selectedCreationSource, setSelectedCreationSource] = React.useState("");
  const [selectedCreatedAt, setSelectedCreatedAt] = React.useState("");
  const [searchCriteria, setSearchCriteria] = React.useState({email: "", event_name: ""});
  const [email, setEmail] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [showDateSelector, setShowDateSelector] = React.useState(false);

  // initial page load
  useEffect(() => {
    if (params) {
      setCurrentPage(params.selectedPage);
      setSearchCriteria(params.criteria);
    } 

    // don't wait for state to change...
    const incoming_selected_page = params !== null ? params?.selectedPage : 1;
    const incoming_criteria = params !== null ? params?.criteria : {email: "", event_name: ""};

    search(incoming_criteria, incoming_selected_page);
  }, []);

  const handleCloseDateSelector = () => setShowDateSelector(false);

  const downloadFile = (data, filename) => {
    const rows = data.split('\n');
    const csvContent = rows.map(row => row.trim()).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleExportData = async () => {
    const API_URL = process.env.REACT_APP_API_URL;

    const headers = {
    'Authorization': localStorage.getItem('token'),
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    };

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email,
      event_name: selectedCreationSource,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    const response = await axios.get(API_URL + `events_export?${constructParamsString(criteria)}`, { headers });
    const filename = 'events_export.csv';

    downloadFile(response.data, filename);
  };

  function constructParamsString(criteria) {
    const params = new URLSearchParams();
  
    if (criteria) {
      for (const [key, value] of Object.entries(criteria)) {
        params.append(key, value);
      }
    }
  
    return params.toString();
  }



  const handleApplyDateFilter = () => {
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";
    const criteria = {
      email: email,
      event_name: selectedCreationSource,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    search(criteria, currentPage);
    setShowDateSelector(false);
  }

  const handleShowCloseDateSelector = () => {
    setShowDateSelector(true);
  }

  const handlePaginationClick = (selectedPage) => {
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email, 
      event_name: selectedCreationSource, 
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    };
    
    setCurrentPage(selectedPage);
    setSearchCriteria(criteria);
    
    search(criteria, selectedPage);
  }

  const updatePerPage = (e) => {
    const per_page = e.target.value;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";
    
    setPerPage(per_page);
    setSearchCriteria(
      {
        email: email, 
        event_name: selectedCreationSource, 
        created_at: selectedCreatedAt,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      }
    );
    
    search(searchCriteria, currentPage, per_page);
  }

  const handleEmailSearch = debounce((e) => {
    const email = e.target.value;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email,
      event_name: selectedCreationSource,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    setSearchCriteria(criteria);
    
    setEmail(email);
    search(criteria, currentPage)
  }, 500); // Adjust the debounce delay as needed (in milliseconds)

  const handleSourceFilter = (name) => {
    const event_name = name;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : "";

    const criteria = {
      email: email,
      event_name: event_name,
      created_at: selectedCreatedAt,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    setSelectedCreationSource(name);

    search(criteria, currentPage);
  }

  const search = (criteria, selectedPage, immediatePerPage) => {
    if (immediatePerPage) {
      dispatch(getEventsAsync({ page: selectedPage, per_page: immediatePerPage, searchCriteria: criteria }));
    } else {
      dispatch(getEventsAsync({ page: selectedPage, per_page: perPage, searchCriteria: criteria }));
    }
  }

  const translateSelectedEvent = (source) => {
    switch (source) {
      case "SCHEDULE_SERVICE":
        return "Schedule Service";
      case "CALL_ROAD_SIDE_ASSISTANCE":
        return "Call Road Side Assistance";
      case "CARRLLY_LOGIN":
        return "Carrlly Login";
      case "CARRLLY_WHATS_MY_CAR_WORTH":
        return "Carrlly What's My Car Worth";
      case "CARRLLY_SERVICE_AND_REPAIR":
        return "Carrlly Service and Repair";
      case "MEET_CARRLLY":
        return "Meet Carrlly";
      case "CARRLLY_SPECIAL":
        return "Carrlly Special";
      default:
        return "All";
    }
  }

  const renderDate = (date) => {
    if (date === null || date === undefined) {
      return "N/A";
    } else {
      moment.locale('en');
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    }
  }

  const renderSelectedDates = () => {
    const formattedStart = moment(startDate).format('MM-DD-YYYY');
    const formattedEndDate = moment(endDate).format('MM-DD-YYYY');

    if (formattedStart === "Invalid date" || formattedEndDate === "Invalid date") {
      return "Date Filter";
    } else {
      return `${formattedStart} - ${formattedEndDate}`;
    }
  }
      

  const renderUsers = () => {
    if (typeof allEvents === 'undefined' || allEvents === null || allEvents.length === 0){
      return <p>No users</p>
    } else {
      return (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Event</th>
              <th>Distinct ID</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {allEvents?.data?.map((event) => (
              <tr key={event.attributes.id}>
                <td>{event.attributes.event_name}</td>
                <td>{event.attributes.distinct_id}</td>
                <td>{renderDate(event.attributes.date)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    }
  }

  return (
    <div>
      <Container className="navbar-margin">
        <Row className="mb-3">
          <Col>
            <div className="d-flex custom-spacing">
              <Dropdown onSelect={handleSourceFilter}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Event Name: {translateSelectedEvent(selectedCreationSource)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="">All</Dropdown.Item>
                  <Dropdown.Item eventKey="SCHEDULE_SERVICE">Schedule Service</Dropdown.Item>
                  <Dropdown.Item eventKey="CALL_ROAD_SIDE_ASSISTANCE">Call Roadside Assistance</Dropdown.Item>
                  <Dropdown.Item eventKey="CARRLLY_LOGIN">Carrlly Login</Dropdown.Item>
                  <Dropdown.Item eventKey="CARRLLY_WHATS_MY_CAR_WORTH">Carrlly What's My Car Worth</Dropdown.Item>
                  <Dropdown.Item eventKey="CARRLLY_SERVICE_AND_REPAIR">Carrlly Service and Repair</Dropdown.Item>
                  <Dropdown.Item eventKey="MEET_CARRLLY">Meet Carrlly</Dropdown.Item>
                  <Dropdown.Item eventKey="CARRLLY_SPECIAL">Carrlly Special</Dropdown.Item>

                  <Dropdown.Item eventKey="5">Service</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="secondary" onClick={handleShowCloseDateSelector}>{renderSelectedDates()}</Button>
            </div>
          </Col>
          <Col>
            <Form.Control size="md" type="text" onChange={handleEmailSearch} placeholder="Search by Email" />
          </Col> 
        </Row>
        <Row>
          <Col className="text-end"> {/* Add className="text-right" to align the content to the right */}
            <Button variant='link' className='' onClick={handleExportData}>Export Data</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {renderUsers()}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="auto" className="mb-2 mr-1">
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{perPage} Per Page</Form.Label>
                <Form.Control as="select" onChange={updatePerPage}>
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="mb-2 mr-1">
            <CustomPagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePaginationClick} />
          </Col>
        </Row>
        <Modal show={showDateSelector} onHide={handleCloseDateSelector}>
          <Modal.Header closeButton>
            <Modal.Title>Date Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <label>Created From:</label>
                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                <label>Created To:</label>
                <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
              </Row>
              <Row>
                <Button variant="link" onClick={() => {setStartDate(""); setEndDate("")}}>Clear Dates</Button>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDateSelector}>
              Close
            </Button>
            <Button variant="primary" onClick={handleApplyDateFilter}>
              Apply Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  )
}

export default Events;