import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getServiceRecords(vehicle_id) {
  const body = {
    "params": {
      vehicle_id
    },
    headers: {
      "Content-Type": 'application/json', 
      "Authorization": localStorage.getItem('token')
    }
  }

  try {
    const response = await axios.get(API_URL + "vehicle_service_records", body);
    const response_obj = {
      status: response.status.code,
      data: response.data,
    }

    

    return response_obj;
  } catch (err) {
    const error_response = {
      status: err.response.status,
      message: "Something went wrong!",
      statusText: err.response.statusText,
    }

    return error_response;
  }
}

export async function uploadServiceRecord(vehicle_id, record_url) {
  const user_body = {
    "upload": {
      vehicle_id,
      record_url
    }
  }

  const headers = { 
    headers: {
      "Content-Type": 'application/json', 
      "Authorization": localStorage.getItem('token')
    }
  };

  try {
    const response = await axios.post(API_URL + "vehicle_service_records", user_body, headers);
    const response_obj = {
      status: response.status,
      data: response.data.data,
    }

    return response_obj;
  } catch (err) {
    const error_response = {
      status: err.response.status,
      message: "Something went wrong!",
      statusText: err.response.statusText,
    }

    return error_response;
  }
}