import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

export async function getCurrentUser(token) {
  
  try {
    const response = await axios.get(API_URL + "current_user", { headers: {"Authorization": token}});
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.resposne.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.resposne.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function callVincue(payload) {
  try {
    const response = await axios.post(API_URL + "vincue", payload.body, { headers: {"Authorization": payload.token }});
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        raw_error: err.response,
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function getVehicleTrims(payload) {
  try {
    const response = await axios.post(API_URL + "vehicle_trims", payload.body, { headers: {"Authorization": payload.token}});
    return response.data
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response
  }
}

export async function authenticate() {
  const auth_body = {
    "userName": "TrustedSale192034",
    "password": "TrustedSalexVINCUE!"
  };

  // const headers = {
  //   headers: {
  //     'Authorization': localStorage.getItem('token'),
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json'
  //   }
  // }

  try {
    const response = await axios.post(API_URL + "auth", auth_body);
    const response_obj = {
      status: response.status,
      provisioned_vins: response.data
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}
