import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getProvisionedVins(filter, ownerFilter, page, token) {
  try {
    const url = `${API_URL}vehicle_registrations?${constructParamsString(filter, ownerFilter, page)}`;

    let response = await axios.get(url, { headers: {"Authorization": token}});
    
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.resposne.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.resposne.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  } 
}

// Helper function to construct parameter string
function constructParamsString(filter, ownerFilter, page) {
  const params = new URLSearchParams();

  if (filter !== undefined && filter !== null && filter !== '') {
    params.append("status_filter", filter);
  }

  if (ownerFilter !== undefined && ownerFilter !== null && ownerFilter !== '') {
    params.append("owner_filter", ownerFilter);
  }

  if (page) {
    params.append("page", page);
  }

  return params.toString();
}

export async function getCurrentUser(token) {
  
  try {
    const response = await axios.get(API_URL + "current_user", { headers: {"Authorization": token}});
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.resposne.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.resposne.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function resendRegistrationSms(vin, phone) {
  const user_body = {
    "provision_vin": {
      vin,
      phone
    }
  };

  const headers = {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await axios.post(API_URL + "resend_registration_sms", user_body, headers);
    const response_obj = {
      status: response.status,
      provisioned_vins: response.data
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}

export async function registerVin(vin, phone) {
  const user_body = {
    "provision_vin": {
      vin,
      phone
    }
  };

  const headers = {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await axios.post(API_URL + "provision_vin", user_body, headers);
    const response_obj = {
      status: response.status,
      provisioned_vins: response.data
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}

export async function accountUpdate(id, email, first_name, last_name, phone) {
  const user_body = {
    "update_account": {
      email,
      first_name,
      last_name,
      phone
    }
  };

  const headers = {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await axios.put(API_URL + "current_user/"+id, user_body, headers);
    const response_obj = {
      status: response.data.status.code,
      token: response.headers.authorization,
      user: response.data.data,
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}

export async function updateVehicle(id, remote_url) {
  const vehicle_body = {
    "vehicle": {
      remote_url
    }
  };

  const headers = {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'Accept': 'applicaiton/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.put(API_URL + "vehicle/" + id, vehicle_body, headers);
    const response_obj = {
      status: response.status,
      data: response.data.data.attributes,
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

       return error_response;
    }
  }
}

export async function addVehicle(vin, year, make, model) {
  const vehicle_body = {
    "vehicle": {
      vin,
      make,
      model,
      year
    }
  };

  const headers = {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await axios.post(API_URL + "vehicle", vehicle_body, headers);
    const response_obj = {
      status: response.status,
      data: response.data.data,
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: err.response.data.data.message,
        statusText: err.response.data.data.message,
      }

      return error_response;
    }
  }
}