import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadServiceRecord, getServiceRecords } from "./serviceRecordAPI";

const initialState = {
  serviceRecords: [],
  fileUploaded: "false",
  status: "idle"
}

export const getServiceRecordsAsync = createAsyncThunk(
  'serviceRecord/getServiceRecords',
  async (payload) => {
    const response = await getServiceRecords(payload.vehicle_id, payload.token)
    return response
  }
)

export const uploadServiceRecordAsync = createAsyncThunk(
  'serviceRecord/uploadServiceRecord',
  async (payload) => {
    const response = await uploadServiceRecord(payload.vehicle_id, payload.record_url, payload.token)
    return response
  }
)

export const serviceRecordSlice = createSlice({
  name: 'serviceRecord',
  initialState,
  reducers: {
    clearServiceRecords: (state) => {
      state.serviceRecords = [];
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(uploadServiceRecordAsync.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(uploadServiceRecordAsync.fulfilled, (state, action) => {
      state.status = 'done'
      state.fileUploaded = 'true'
    })
    .addCase(uploadServiceRecordAsync.rejected, (state, action) => {

    })
    .addCase(getServiceRecordsAsync.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(getServiceRecordsAsync.fulfilled, (state, action) => {
      state.serviceRecords = JSON.stringify(action.payload.data.data);
      state.fileUploaded = 'false'
      state.status = 'done'
    })
    .addCase(getServiceRecordsAsync.rejected, (state, action) => {

    })
  }
});

export const getStatus = (state) => state.serviceRecord.status;
export const fileUploaded = (state) => state.serviceRecord.fileUploaded;
export const serviceRecords = (state) => state.serviceRecord.serviceRecords;

export const { clearServiceRecords } = serviceRecordSlice.actions;

export default serviceRecordSlice.reducer;