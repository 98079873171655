import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function constructParamsString(page, per_page, searchCriteria = null) {
  const params = new URLSearchParams();
  if (page) {
    params.append("page", page);
  }

  if (per_page) {
    params.append("per_page", per_page);
  }

  if (searchCriteria) {
    for (const [key, value] of Object.entries(searchCriteria)) {
      params.append(key, value);
    }
  }

  return params.toString();
}

export async function getEvents(page, per_page, searchCriteria = null) {
  try {
    const headers = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    let response = null;

    if (searchCriteria) {
      response = await axios.get(API_URL + `events?${constructParamsString(page, per_page, searchCriteria)}`, headers);
    } else {
      response = await axios.get(API_URL + `events?${constructParamsString(page, per_page)}`, headers);
    }

    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        raw_error: err.response,
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function postEvent(event_name, email, token) {

  try {
    const currentDate = new Date();

    const eventMessage = {
      "event_name": event_name,
      "date": currentDate,
      "distinct_id": email,
      "country": "USA",
      "browser": navigator.userAgent,
      "browser_version": navigator.appVersion
    }

    const response = await axios.post(API_URL + "events", eventMessage, { headers: {"Authorization": token}});
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        raw_error: err.response,
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}