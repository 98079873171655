import { useSelector } from "react-redux";
import Truck from "../../images/Truck";
import Schedule from "../../images/Schedule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { postEvent } from "src/services/events/eventApi";
import { getUser } from "src/services/login/loginSlice";


function Roadside() {
  const currentUser = useSelector(getUser);

  const callIt = () => {
    postEvent("CALL_ROAD_SIDE_ASSISTANCE", currentUser.email, localStorage.getItem('token'))
    window.location = "tel:1-888-252-8872";
  }
  
  return (
    <div className="service-page">
      <div className="service-page-header">
        <div className="service-page-header-text">
          Roadside Assistance
        </div>
        <div className="button-image-grey">
          <Truck button-type="service-button-gear"/>
        </div>
      </div>
      <div className="service-page-body">
        <div className="service-page-bold">Help is just a quick call away</div>
        <br/>
        <div>
          We’re sorry to hear you are experiencing trouble with your vehicle and are here to help you with:
          <br/>
          <br/>
          <ul>
            <li>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 10L9 12L13 8" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Towing
            </li>
            <li>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 10L9 12L13 8" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Lock-out service
            </li>
            <li>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 10L9 12L13 8" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Fuel & fluid delivery
            </li>
            <li>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 10L9 12L13 8" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              Battery boost/jump services
            </li>
            <li>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 10L9 12L13 8" stroke="#0F75BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              Rental car/rideshare services
            </li>
          </ul>
        </div>

      </div>
      <div className="service-page-sub-info">
        <Schedule />
        <div className="service-page-sub-info-text">
          Available 24 Hours A Day / 7 Days A Week
        </div>
      </div>
      <div className="service-page-buttons">
        <button className="button-green" onClick={callIt}>
          <span className="button-green-icon"><FontAwesomeIcon icon={faPhone} /></span>
          <span className="button-green-text">Call us now</span>
        </button>
      </div>
      <div className="service-page-footer">

      </div>
    </div>
  )
}

export default Roadside;