import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from 'react';
import './dealer-barcode-scanner.scss';

import { 
  fetchMetrics,
  getUserProvisionedMetrics,
  getCallServiceAndRepairCounts,
  getCallServiceAndRepairDates,
  getCallServiceAndRepairTotal,
  getMeetCarrllyDates,
  getMeetCarrllyCounts,
  getMeetCarrllyTotal,
  getCarrllyLoginDates,
  getCarrllyLoginCounts,
  getCarrllyLoginTotal,
  getCarrllySpecialsCounts,
  getCarrllySpecialsDates,
  getCarrllySpecialsTotal,
  getCallRoadSideAssistanceCounts,
  getCallRoadSideAssistanceDates,
  getCallRoadSideAssistanceTotal,
  getScheduleServiceCounts,
  getScheduleServiceDates,
  getScheduleServiceTotal,
  getManualRegistrationDates,
  getManualRegistrationCounts,
  getManualRegistrationTotal,
  getWhatsMyCarWorthCounts,
  getWhatsMyCarWorthDates,
  getWhatsMyCarWorthTotal,
  fetchUserCreationMetrics,
} from "src/services/metrics/metricSlice";
import { 
  Card,
  Container, 
  Row,
  Col,
} from "react-bootstrap";

import "./dealer-dashboard.scss"
import Chart from "react-google-charts";

function DealerDashboard({setSelectedPage}) {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();

  const userProvisionedMetrics = useSelector(getUserProvisionedMetrics);

  const meetCarrllyDates = useSelector(getMeetCarrllyDates);
  const meetCarrllyCounts = useSelector(getMeetCarrllyCounts);
  const meetCarrllyTotal = useSelector(getMeetCarrllyTotal);

  const manualRegistrationDates = useSelector(getManualRegistrationDates);
  const manualRegistrationCounts = useSelector(getManualRegistrationCounts); 
  const manualRegistrationTotal = useSelector(getManualRegistrationTotal); 

  const carrllyLoginDates = useSelector(getCarrllyLoginDates);
  const carrllyLoginCounts = useSelector(getCarrllyLoginCounts);
  const carrllyLoginTotal = useSelector(getCarrllyLoginTotal);

  const callServiceAndRepairCounts = useSelector(getCallServiceAndRepairCounts);
  const callServiceAndRepairDates = useSelector(getCallServiceAndRepairDates);
  const callServiceAndRepairTotal = useSelector(getCallServiceAndRepairTotal);

  const callRoadSideAssistanceCounts = useSelector(getCallRoadSideAssistanceCounts);
  const callRoadSideAssistanceDates = useSelector(getCallRoadSideAssistanceDates);
  const callRoadSideAssistanceTotal = useSelector(getCallRoadSideAssistanceTotal);

  const scheduleServiceDates = useSelector(getScheduleServiceDates);
  const scheduleServiceCounts = useSelector(getScheduleServiceCounts);
  const scheduleServiceTotal = useSelector(getScheduleServiceTotal);

  const carrllySpecialCounts = useSelector(getCarrllySpecialsCounts);
  const carrllySpecialDates = useSelector(getCarrllySpecialsDates);
  const carrllySpecialTotal = useSelector(getCarrllySpecialsTotal);

  const whatsMyCarWorthCounts = useSelector(getWhatsMyCarWorthCounts);
  const whatsMyCarWorthDates = useSelector(getWhatsMyCarWorthDates);
  const whatsMyCarWorthTotal = useSelector(getWhatsMyCarWorthTotal);

  useEffect(() => {
    dispatch(fetchMetrics({token}));
    dispatch(fetchUserCreationMetrics({token}));
  }, [dispatch, token]);

  const meetCarrllyData = () => {    
    const return_data = [["Date", "Count"]];
    if (meetCarrllyDates !== undefined && meetCarrllyDates.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < meetCarrllyDates?.length; i++) {
      return_data.push([meetCarrllyDates[i], meetCarrllyCounts[i]]);
    }
    return return_data;
  }

  const usersProvisionedData = () => {
    const return_data = [["Date", "Count"]];
    if (userProvisionedMetrics !== undefined && userProvisionedMetrics.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < userProvisionedMetrics?.length; i++) {
      const userProvisionedMetricsData = userProvisionedMetrics[i];
      const userProvisionedMetricsDate = userProvisionedMetricsData.date;
      const userProvisionedMetricsCount = userProvisionedMetricsData.count;
      const userProvisionedMetricsDateCount = [userProvisionedMetricsDate, userProvisionedMetricsCount];
      return_data.push(userProvisionedMetricsDateCount);
    }
    
    return return_data;
  }

  const carrllyLoginData = () => {
    const return_data = [["Date", "Count"]];
    if (carrllyLoginDates === undefined || carrllyLoginDates?.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < carrllyLoginDates?.length; i++) {
      return_data.push([carrllyLoginDates[i], carrllyLoginCounts[i]]);
    }

    return return_data;
  }

  const manualRegistrationData = () => {
    const return_data = [["Date", "Count"]];

    if (manualRegistrationDates === undefined || manualRegistrationDates.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < manualRegistrationDates?.length; i++) {
      return_data.push([manualRegistrationDates[i], manualRegistrationCounts[i]]);
    }

    return return_data;
  }

  const whatsMyCarWorthData = () => {
    const return_data = [["Date", "Count"]];
    if (whatsMyCarWorthDates === undefined || whatsMyCarWorthDates?.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < whatsMyCarWorthDates?.length; i++) {
      return_data.push([whatsMyCarWorthDates[i], whatsMyCarWorthCounts[i]]);
    }

    return return_data;
  }

  const callServiceAndRepairData = () => {
    const return_data = [["Date", "Count"]];
    if (callServiceAndRepairDates === undefined || callServiceAndRepairDates?.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < callServiceAndRepairDates?.length; i++) {
      return_data.push([callServiceAndRepairDates[i], callServiceAndRepairCounts[i]]);
    }

    return return_data;
  }

  const carrllySpecialData = () => {
    const return_data = [["Date", "Count"]];
    if (carrllySpecialDates === undefined || carrllySpecialDates?.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < carrllySpecialDates?.length; i++) {
      return_data.push([carrllySpecialDates[i], carrllySpecialCounts[i]]);
    }

    return return_data;
  }

  const callRoadSideAssistanceData = () => {
    const return_data = [["Date", "Count"]];
    if (callRoadSideAssistanceDates === undefined || callRoadSideAssistanceDates?.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }
    
    for (let i = 0; i < callRoadSideAssistanceDates?.length; i++) {
      return_data.push([callRoadSideAssistanceDates[i], callRoadSideAssistanceCounts[i]]);
    }

    return return_data;
  }

  const scheduleServiceData = () => {
    const return_data = [["Date", "Count"]];

    if (scheduleServiceDates === undefined || scheduleServiceDates?.length === 0) {
      return_data.push(["No Data", 0]);
      return return_data;
    }

    for (let i = 0; i < scheduleServiceDates?.length; i++) {
      return_data.push([scheduleServiceDates[i], scheduleServiceCounts[i]]);
    }

    return return_data;
  }

  return (
    <>
      <Container className="navbar-margin" >
        <Row xs={1} md={3} lg={3} className="m-3">
          <Col className="mb-6">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Carrlly Logins</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={carrllyLoginData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {carrllyLoginTotal} in the last month
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Carrlly Specials</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={carrllySpecialData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {carrllySpecialTotal} in the last month
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Call Service and Repair</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={callServiceAndRepairData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {callServiceAndRepairTotal} in the last month.
              </Card.Footer>
            </Card>
          </Col>      
        </Row>
        <Row xs={1} md={3} lg={3} className="m-3">
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Roadside Assistance</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={callRoadSideAssistanceData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {callRoadSideAssistanceTotal} in the last month
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Schedule Service</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={scheduleServiceData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {scheduleServiceTotal} in the last month
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Meet Carrlly</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={meetCarrllyData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {meetCarrllyTotal} in the last month
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={3} lg={3} className="m-3">
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>What's My Car Worth</Card.Title>
                <Card.Text>
                  <Chart
                    chartType="LineChart"
                    data={whatsMyCarWorthData()}
                    width="100%"
                    height="100%"
                    legendToggle={false}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                {whatsMyCarWorthTotal} in the last month
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={1} lg={1} className="m-3">
          <Col>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Users Provisioned</Card.Title>
              <Card.Text>
                <Chart
                  chartType="LineChart"
                  data={usersProvisionedData()}
                  width="100%"
                  height="100%"
                  legendToggle={false}
                />
              </Card.Text>
            </Card.Body>
          </Card>          
          </Col>
        </Row>
      </Container>
    </>
  );
} 

export default DealerDashboard;