import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import {
  updateEmail,
  updateFirstName,
  updateLastName,
  updatePhone,
  updateAdmin,
  updateDealer,
  userToEdit,
  resetSaveUserMessage,
  resetUserToEdit,
  saveUserMessage,
  createUserAsync,
} from "src/services/dealer/dealerSlice";

import RouteConstants from "../RouteConstants";
import { render } from "@testing-library/react";

const CreateUser = ({updateUrl, params}) => {
  const dispatch = useDispatch();
  const message = useSelector(saveUserMessage);
  const [showMessage, setShowMessage] = React.useState(false);
  const user = useSelector(userToEdit);

  const selectedPage = params?.selectedPage;
  const searchCriteria = params?.criteria;

  const handleEmailChange = (e) => {
    dispatch(updateEmail(e.target.value));
  }

  const handleFirstNameChange = (e) => {
    dispatch(updateFirstName(e.target.value));
  }

  const handleLastNameChange = (e) => {
    dispatch(updateLastName(e.target.value));
  }

  const handlePhoneNumberChange = (e) => {
    dispatch(updatePhone(e.target.value));
  }

  const handleAdminChange = (e) => {
    dispatch(updateAdmin(e.target.checked));
  }

  const handleDealerChange = (e) => {
    dispatch(updateDealer(e.target.checked));
  }

  const setShow = () => {
    setShowMessage(false);
    dispatch(resetSaveUserMessage());
  }

  const renderSaveMessage = () => {
    if (message?.status === 'success') {
      return (
        <Row>
          <Col>
            <Alert variant="success" onClose={() => setShow()} dismissible>{message.message}</Alert>
          </Col>
        </Row>
      )
    } else if (message?.status === 'failed') {
      return (
        <Row>
          <Col>
            <Alert variant="danger" onClose={() => setShow()} dismissible>{message.message} </Alert>
          </Col>
        </Row>
      )
    }
  }

  const backToUsers = () => {
    dispatch(resetSaveUserMessage());
    dispatch(resetUserToEdit());
    updateUrl(RouteConstants.USERS, null, {selectedPage: selectedPage, criteria: searchCriteria});
  }

  const handleCreateUser = () => {
    // create user
    const userToCreate = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      dealer: user.dealer,
      admin: user.admin,
    }

    dispatch(createUserAsync(userToCreate));
  }


  return (
    <div>
      <Container className="navbar-margin">
        {renderSaveMessage()}

        <Row>
          <Col>
            <Button onClick={backToUsers} variant="link">Back to Users</Button>
          </Col>
        </Row>
        <Row>
        <Col>
            <Form>
              <fieldset>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" value={user?.email} onChange={handleEmailChange} placeholder="Enter email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" value={user?.first_name} onChange={handleFirstNameChange} placeholder="First Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" value={user?.last_name} onChange={handleLastNameChange} placeholder="Last Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" value={user?.phone} onChange={handlePhoneNumberChange} placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicAdmin">
                  <Form.Check type="checkbox" label="Admin" checked={user?.admin} onChange={handleAdminChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicDealer">
                  <Form.Check type="checkbox" label="Dealer" checked={user?.dealer} onChange={handleDealerChange} />
                </Form.Group>
                <Button variant="primary" type="button" onClick={handleCreateUser}>
                  Save User
                </Button>
              </fieldset>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CreateUser;