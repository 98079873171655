import wheresMyVin from "./WheresMyVin.png";


function WheresMyVin() {
  return (
    <div className="wheres-my-vin-wrapper">
      <img className="wheres-my-vin-wrapper-image" src={wheresMyVin} />
    </div>
  )
}

export default WheresMyVin;