import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function createUser(userToCreate) {
  try {
    const headers = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    const user = {
      user: {
        email: userToCreate.email,
        first_name: userToCreate.first_name,
        last_name: userToCreate.last_name,
        phone: userToCreate.phone,
        dealer: userToCreate.dealer,
        admin: userToCreate.admin
      }
    }

    const response = await axios.post(API_URL + "users", user, headers);

    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function getUser(id) {
  try {
    const headers = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    const response = await axios.get(API_URL + `users/${id}`, headers);

    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      } 
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function updateUser(user_to_edit) {
  try {
    const headers = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    const response = await axios.put(API_URL + `users/${user_to_edit.id}`, user_to_edit, headers);

    return response.data;
  } catch(err) {
    let error_response = {}
    
    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }
    
    return error_response;
  }
}

export async function getUsers(page, per_page, searchCriteria = null) {
  try {
    const headers = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    let response = null;

    if (searchCriteria) {
      response = await axios.get(API_URL + `users?${constructParamsString(page, per_page, searchCriteria)}`, headers);
    } else {
      response = await axios.get(API_URL + `users?${constructParamsString(page, per_page)}`, headers);
    }
    
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

export async function getRegistrationUploadFiles(page) {
  try {
    const headers = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    const response = await axios.get(API_URL + `registration_upload?${constructParamsString(page)}`, headers);

    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) { 
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}

function constructParamsString(page, per_page, searchCriteria = null) {
  const params = new URLSearchParams();
  if (page) {
    params.append("page", page);
  }

  if (per_page) {
    params.append("per_page", per_page);
  }

  if (searchCriteria) {
    for (const [key, value] of Object.entries(searchCriteria)) {
      params.append(key, value);
    }
  }

  return params.toString();
}

export async function uploadFile(data) {
  try {

    const formData = new FormData();
    formData.append('file', data, data.name);

    const response = await axios.post(API_URL + "registration_upload", formData, {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch(err) {
    let error_response = {}

    if (err.response.status === 401) {
      error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
    } else {
      error_response = {
        status: err.response.status,
        message: "Something went wrong!",
        statusText: err.response.statusText,
      }
    }

    return error_response;
  }
}