import { configureStore } from '@reduxjs/toolkit';
import recordSlice from '../components/records/recordSlice';
import gloveBoxSlice from '../components/glovebox/gloveBoxSlice';
import serviceRecordSlice from '../services/service-record/serviceRecordSlice';
import accountSlice from '../services/account/accountSlice';
import passwordSlice from '../services/password/passwordSlice';
import dealerSlice from '../services/dealer/dealerSlice';
import loginSlice from '../services/login/loginSlice';
import metricSlice from '../services/metrics/metricSlice';
import eventSlice from 'src/services/events/eventSlice';
import { loadState, saveState } from '../localStorage';

const store = configureStore({
  reducer: {
    records: recordSlice,
    glovebox: gloveBoxSlice,
    serviceRecord: serviceRecordSlice,
    account: accountSlice,
    password: passwordSlice,
    dealer: dealerSlice,
    login: loginSlice,
    metrics: metricSlice,
    events: eventSlice,
  },
  preloadedState: loadState(),
});

store.subscribe(() => {
  saveState(store.getState());
});

export default store;