import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  uploadServiceRecordAsync,
  getServiceRecordsAsync,
  getStatus,
  serviceRecords
} from '../../services/service-record/serviceRecordSlice';

import { getUser } from 'src/services/login/loginSlice';

import { 
  selectedVehicle,
  getToken
} from "../../services/account/accountSlice"

import "../../services/service-record/serviceRecord.scss";

import SimpleFileUpload from 'react-simple-file-upload'
import { fileUploaded } from '../../services/service-record/serviceRecordSlice';


function VehicleServiceRecords() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const uploadedServiceRecords = useSelector(serviceRecords);
  const token = useSelector(getToken);

  const vehicle_id = currentUser?.vehicles[0]?.id;

  const status = useSelector(getStatus);
  const isFileUploaded = useSelector(fileUploaded);

  if (status === 'idle') {
    dispatch(getServiceRecordsAsync({vehicle_id}));
  }
  
  useEffect(() => {
    // update list of images if new file is uploaded
    if ( isFileUploaded === 'true' ) {
      dispatch(getServiceRecordsAsync({vehicle_id}))
    }
  }, [isFileUploaded]);

  function handleFile(url){
    const record_url = url;
    dispatch(uploadServiceRecordAsync({vehicle_id, record_url}));
  }

  const myServiceRecords = () => {
    if (uploadedServiceRecords && uploadedServiceRecords.length > 0) {
      return JSON.parse(uploadedServiceRecords).map((serviceRecord) => {
        return (
          <div className="service-record-records">
            <a target="_blank" href={serviceRecord.attributes.record_url}>
              <img className="service-record-image" src={serviceRecord.attributes.record_url} key={serviceRecord.id} alt="record" width="150"></img>
            </a>
          </div>
        )
      })
    } else {
      return <div></div>
    }
  } 

  return (
    <div>
      <div>To add a new service record, tap on the "Drop File to Upload"  icon and then select the option to either upload or snap a photo.</div>

      <SimpleFileUpload
        apiKey="a88d5fa87d1aca4ec0ed6cadbeb3f11f"
        onSuccess={handleFile}
        preview={false}
      />
      <hr/>
      <div className="service-records-wrapper">
        {myServiceRecords()}
      </div>
    </div>
  ) 
}

export default VehicleServiceRecords;