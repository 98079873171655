import React, { useEffect } from "react";
import { Button, Container, Form, Row, Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { 
  uploadRegistrationFile,
  getUploadedFiles,
  uploadedFiles,
  uploadedFilesTotalPages,
  uploadedFilesCurrentPage,
} from 'src/services/dealer/dealerSlice';

const ServiceSchedule = () => {
  const dispatch = useDispatch();
  const files = useSelector(uploadedFiles);
  const currentPage = useSelector(uploadedFilesCurrentPage);
  const totalPages = useSelector(uploadedFilesTotalPages);

  const [file, setFile] = React.useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();;
    dispatch(uploadRegistrationFile(file));
  }

  useEffect(() => {
    dispatch(getUploadedFiles({page: currentPage}));
  }, []);

  const renderUploadFileErrors = (error_array) => {
    return (
      <ul>
        {error_array?.map((error, index) => {
          return (
            <li>
              {error.user?.email} - {error.errors[0]}
            </li>
          ) 
        })}
      </ul>
    )
  }

  const handlePaginationClick = (e) => {
    e.preventDefault();
    const page = e.target.text;
    dispatch(getUploadedFiles({page: page}));
  }
  
  const renderUploadedFiles = () => {
    if (typeof files === 'undefined' || files === null || files.length === 0){
      return <p>No files uploaded</p>
    } else {
      if (files.status !== 400) {
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  Filename
                </th>
                <th>
                  Status
                </th>
                <th>
                  Created At
                </th>
                <th>
                  Successful
                </th>
                <th>
                  Failed
                </th>
                <th>
                  Upload Errors
                </th>
              </tr>
            </thead>
            <tbody>
            {files.data?.map((file, index) => {
              return (
                <tr>
                  <td>
                    {file.attributes.file_name}
                  </td>
                  <td>
                    {file.attributes.status}
                  </td>
                  <td>
                    {file.attributes.created_at}
                  </td>
                  <td>
                    {file.attributes.success}
                  </td>
                  <td>
                    {file.attributes.failed}
                  </td>
                  <td>
                    {renderUploadFileErrors(file.attributes.upload_errors?.errors)}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </Table>
        )
      } else {
        return <p>{files.message}</p>
      }
    }
  }

  let items = [];

  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item key={number} onClick={handlePaginationClick} active={number === currentPage}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <Container className="navbar-margin">
        <Row className="mb-3">
          <h1>Service Schedule</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Service Schedule</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button type="submit" className="btn btn-primary">Upload</Button>
          </Form>
        </Row>
        <Row className="mb-3">
          <hr/>
          <h2>Uploaded Files</h2>
          <div className="p-1">
            <Pagination size="sm">{items}</Pagination>
          </div>
          {renderUploadedFiles()}
        </Row>
      </Container>
    </>
  );
};

export default ServiceSchedule;