import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/Modal';
import { updateVehicleAsync, vehicleId, setVehicleUrl } from "../services/account/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { getUser } from "../services/login/loginSlice";
import { vehicleUrl } from "../services/account/accountSlice";

function Vehicle({url}) {
  const currentUser = useSelector(getUser);
  const remote_url = useSelector(vehicleUrl);

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const vehicle_id = currentUser?.vehicles[0]?.id;

  useEffect(() => {
    const url = currentUser?.vehicles[0]?.remote_url;
    if (remote_url === '' || remote_url === null) {
      dispatch(setVehicleUrl(url));
    }
  }, [currentUser]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleVehicleImageUpload = (url) => {
    const record_url = url;

    dispatch(updateVehicleAsync({vehicle_id, record_url}));
    dispatch(setVehicleUrl(record_url));
    setShowModal(false);
  };

  const renderModal = () => {
    if (showModal) {
      return <Modal onClose={handleCloseModal} handleVehicleFile={handleVehicleImageUpload} />
    }
  }

  const renderImage = () => {
    const sizedUrl = remote_url + "?w=130&h=130&fit=crop";
    if (url) {
      return (
        <div className="vehicle-details-body-image-container">
          <img className="vehicle-details-body-image" src={sizedUrl} alt="vehicle-image" onClick={handleOpenModal}/>
          {renderModal()}
        </div>
      )
    } else {
      return (
        <div className="vehicle-details-body-image-container">
          <FontAwesomeIcon icon={faImage} className="vehicle-details-body-image" onClick={handleOpenModal}/>
          <div className="vehicle-details-body-image-text" onClick={handleOpenModal}>Upload Image</div>
          {renderModal()}
        </div>
      )
    }
  }

  
  return (
    <div className="vehicle-details-body-image-container">
      {renderImage()}
      {renderModal()}
    </div>
  )
}

export default Vehicle;