import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import Logo from 'src/components/Logo';
import { Navbar } from "react-bootstrap";
import RouteConstants from "./RouteConstants";

import { 
  logout as logoutAccount
} from "src/services/account/accountSlice";

import {
  logout as clearMetrics
} from "src/services/metrics/metricSlice";

import { getUser } from "src/services/login/loginSlice";

import { logout } from "src/services/login/loginSlice";

const DealerHeader = ({updateUrl}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);

  const firstName = currentUser?.first_name
  const lastName = currentUser?.last_name
  const fullName = firstName + ' ' + lastName;

  const handleLogout = () => {
    dispatch(logoutAccount());
    dispatch(clearMetrics());
    dispatch(logout());
  }

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="/"><Logo /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Body>
            <Nav className="me-auto" navbarScroll>
              <Nav.Link onClick={() => updateUrl(RouteConstants.DASHBOARD)}>Dashboard</Nav.Link>
              <Nav.Link onClick={() => updateUrl(RouteConstants.USER_UPLOAD)}>Service Schedule Upload</Nav.Link>
              <Nav.Link onClick={() => updateUrl(RouteConstants.USERS)}>Users</Nav.Link>
              <Nav.Link onClick={() => updateUrl(RouteConstants.EVENTS)}>Events</Nav.Link>
            </Nav>
            
            <NavDropdown title={fullName} align="end" id="basic-nav-dropdown">
              <NavDropdown.Item href="/logout" onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default DealerHeader;
