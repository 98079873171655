const RouteConstants = {
  // Dealer App Routes
  CREATE_USER: 'create-user',
  DASHBOARD: 'dashboard',
  EDIT_USER: 'edit-user',
  USER_UPLOAD: 'service-schedule',
  USERS: 'users',
  EVENTS: 'events',
  
  // Global routes
  DEALER: 'dealer/',
  PROVISION: 'provision',

  // Consumer routes
  ACCOUNT: 'account',
  GLOVEBOX: 'glovebox',
  RECORDS: 'records',
};

export default RouteConstants;