import React from "react";
import DealerDashboard from "./DealerDashboard";
import Events from "./events/Events";
import DealerHeader from "./DealerHeader";
import Users from "./users/Users";
import CreateUser from "./users/CreateUser";
import EditUser from "./users/EditUser";
import ServiceSchedule from "./service-schedule/ServiceSchedule";
import RouteConstants from "./RouteConstants";

export const DealerApp = () => {
  const [selectedPage, setSelectedPage] = React.useState(RouteConstants.DASHBOARD);
  const [params, setParams] = React.useState(null);
  const [eventParams, setEventParams] = React.useState(null);

  const updateUrl = (page, any_state, params=null) => {
    window.history.pushState(any_state, '', page);
    if (params) {
      setParams(params);
    }
    setSelectedPage(page);
  }

  const renderPage = () => {
    if (selectedPage === RouteConstants.DASHBOARD) {
      return <DealerDashboard />
    } else if (selectedPage === RouteConstants.USER_UPLOAD) {
      return <ServiceSchedule />
    } else if ( selectedPage === RouteConstants.USERS) {
      return <Users updateUrl={updateUrl} params={params}/>
    } else if ( selectedPage === RouteConstants.CREATE_USER) {
      return <CreateUser updateUrl={updateUrl}/>
    } else if ( selectedPage === RouteConstants.EDIT_USER) {
      return <EditUser updateUrl={updateUrl} params={params}/>
    } else if ( selectedPage === RouteConstants.EVENTS) {
      return <Events params={eventParams} />
    } else {
      return <DealerDashboard />
    }
  }

  return (
    <div>
      <h1>Dealer App</h1>
      <DealerHeader updateUrl={updateUrl}/>
      {renderPage()}
    </div>
  );
};
