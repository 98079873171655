import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import {
  vehicleTrims,
  getVincueData,
} from "./gloveBoxSlice";

import {
  getToken,
  getUser,
} from '../../services/login/loginSlice';
import { postEvent } from 'src/services/events/eventApi';

const WhatsMyCarWorth = () => {
  const [selectedTrim, setSelectedTrim] = useState("");
  const handleSelectedTrim = (event) => {
    setSelectedTrim(event);
  }

  const dispatch = useDispatch();
  const vehicleTrimValues = useSelector(vehicleTrims);
  const api_token = useSelector(getToken);
  const currentUser = useSelector(getUser);

  const account_first_name = currentUser.first_name;
  const account_last_name = currentUser.last_name;
  const account_phone = currentUser.phone;
  const account_email = currentUser.email;
  const account_vehicle_vin = currentUser.vehicles[0]?.vin;

  const handleCarWorthSubmit = (event) => {
    event.preventDefault();
    postEvent("WHATS_MY_CAR_WORTH", currentUser.email ,localStorage.getItem('token'));
    const api_body = {
      "VehicleName": account_vehicle_vin,
      "Odometer": event.target.odometer.value,
      "Customer": {
          "FirstName": account_first_name,
          "LastName": account_last_name,
          "Phone": account_phone,
          "Email": account_email
      }
    }

    if (selectedTrim.value) {
      api_body["TrimName"] = selectedTrim.value;
    }

    const payload = { token: api_token, body: api_body}

    postEvent('CARRLLY_WHATS_MY_CAR_WORTH', account_email, api_token);

    dispatch(getVincueData(payload))
  }

  const renderTrimSelect = () => {
    if (vehicleTrimValues && vehicleTrimValues.length > 0) {
      let trimOptions = vehicleTrimValues.map((vehicle) => {
        return { value: vehicle.ModelTrim, label: vehicle.VehicleName }
      })

      return (
        <div className="worth-page-form-group">
          <label htmlFor="trimValue">Select Trim</label>
          <Select 
            onChange={handleSelectedTrim} 
            options={trimOptions} 
            value={selectedTrim}

          />
        </div>
      )
    }
  }

  return (
    <div className="worth-page">
      <form onSubmit={handleCarWorthSubmit}>
        <div className="worth-page-form-group">
          <label htmlFor="odometer">Odometer Reading</label>
          <input id="odometer" className="login-text" type="text" />  
        </div>
        {renderTrimSelect()}
        <div className="worth-page-form-action">
          <button>What's My Car Worth</button>
        </div>
      </form>
    </div>
  )
}

export default WhatsMyCarWorth;