import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function updatePassword(email, password, password_confirmation, reset_password_token) {
  const user_body = {
    "user": {
      email,
      password,
      password_confirmation,
      reset_password_token
    }
  };

  try {
    const response = await axios.patch(API_URL + "forgot_password", user_body);
    const response_obj = {
      status: response.status,
      message: response.data.data.message,
      error_message: response.data.data.error_message
    }

    return response_obj;
  } catch (err) {
    const error_response = {
      status: err.response.status,
      message: err.response.data.data.message,
      error_message: err.response.data.data.error_message,
      statusText: err.response.statusText,
    }
    
    return error_response;
  }
}

export async function requestPasswordReset(email) {
  const user_body = {
    "user": {
      email
    }
  };

  try {
    const response = await axios.post(API_URL + "forgot_password", user_body);
    const response_obj = {
      status: response.status,
      message: response.data.message
    }

    return response_obj;
  } catch (err) {
    const error_response = {
      status: err.response.status,
      message: err.response.data.message,
      statusText: err.response.statusText,
    }

    return error_response;
  }
}