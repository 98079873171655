import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const VIN_API_URL = process.env.REACT_APP_VIN_API_URL;

export async function vinLookup(vin) {
  try {
    const response = await axios.get(VIN_API_URL + "/" + vin + "?format=json");
    const make = response.data["Results"][0]["Make"]
    const model = response.data["Results"][0]["Model"]
    const year = response.data["Results"][0]["ModelYear"]
    
    let response_obj = {};

    if (response.data["Results"][0]["ErrorCode"] === "0") {
      response_obj = {
        status: response.status,
        data: {
          "vin": vin,
          "make": make,
          "model": model,
          "year": year
        }
      }
    } else {
      response_obj = {
        status: 422,
        data: {
          error: "Unable to decode VIN, please check and try again"
        }
      }
    }

    return response_obj;
  } catch (err) {
    const error_response = {
      status: "500",
      message: "Something went wrong",
      statusText: err.response.statusText,
    }

    return error_response
  }
}

export async function updateUser(email, first_name, last_name, phone, password) {
  const user_body = {
    "account_update": {
      email,
      first_name,
      last_name,
      phone
    }
  };

  try {
    const response = await axios.patch(API_URL + "signup", user_body);

    const response_obj = {
      status: response.data.status.code,
      user: response.data.data,
    }

    return response_obj;
  } catch (err) {
    const error_response = {
      status: err.response.status,
      message: "Something went wrong!",
      statusText: err.response.statusText,
    }

    return error_response;
  }
}

export async function registerDealer(email, first_name, last_name, password, dealer) {
  const user_body = {
    "user": {
      email,
      first_name,
      last_name,
      password,
      dealer
    }
  };

  try {
    const response = await axios.post(API_URL + "signup", user_body);

    const response_obj = {
      status: response.data.status.code,
      token: response.headers.authorization,
      user: response.data.data,
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }
      
      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: err.response.data.status.message,
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}


export async function registerUser(email, first_name, last_name, phone, terms_accepted) {
  const user_body = {
    "user": {
      email,
      first_name,
      last_name,
      phone,
      accepted_terms: terms_accepted
    }
  };

  try {
    // const response = await axios.post(API_URL + "users", user_body);
    const response = await axios.post(API_URL + "manual_registration", user_body);
    const response_obj = {
      status: response.status,
      token: response.headers.authorization,
      payload: response.data.data,
      user: response.data.data.attributes,
    }

    return response_obj;
  } catch (err) {
    if (err.response.status === 401) {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    } else {
      const error_response = {
        status: err.response.status,
        message: err.response.data,
        payload: err.response.data,
        statusText: err.response.statusText,
      }

      return error_response;
    }
  }
}
