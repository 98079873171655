import { 
  createSlice, 
  createAsyncThunk 
} from '@reduxjs/toolkit';

import { 
  getEvents, 
  // getEvent, 
  // createEvent, 
  // updateEvent, 
  // deleteEvent 
} from './eventApi';

export const getEventsAsync = createAsyncThunk(
  'events/getEvents',
  async (payload) => {
    const response = await getEvents(payload.page, payload.per_page, payload.searchCriteria);
    return response;
  }
);

export const eventSlice = createSlice({
  name: 'events',
  initialState: {
    events: [],
    status: 'idle',
    error: null,
    getEventsTotalPages: 0,
  },
  reducers: {
    // eventAdded: (state, action) => {
    //   state.events.push(action.payload);
    // },
    // eventUpdated: (state, action) => {
    //   const { id, name, email, role } = action.payload;
    //   const existingEvent = state.events.find(event => event.id === id);
    //   if (existingEvent) {
    //     existingEvent.name = name;
    //     existingEvent.email = email;
    //     existingEvent.role = role;
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEventsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = action.payload;
        state.getEventsTotalPages = action.payload.meta.total_pages;
      })
      .addCase(getEventsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const events = (state) => state.events.events;
export const getEventsTotalPages = (state) => state.events.getEventsTotalPages;

// export const { 
//   getEvents,
//   // eventAdded, 
//   // eventUpdated 
// } = eventSlice.actions;

export default eventSlice.reducer;