import React from 'react';
import { Provider } from 'react-redux';
import store from './app/store';
import ReactDOM from 'react-dom/client';
import AppWrapper from './AppWrapper';

import 'bootstrap/dist/css/bootstrap.min.css';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  </React.StrictMode>
);

