import circleCheckimage from "../../images/circle-check.svg";

function CircleCheck({title}) {
  return (
    <div className="record-page-certified-vehicle-header">
      <div>
        <img src={circleCheckimage} className="record-page-service-circle-check" />
      </div>
      <div>
        <span className="record-page-service-circle-check-header">{title}</span>
      </div>
    </div>
  )
}

export default CircleCheck;